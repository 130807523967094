import {
  AxIcon,
  type IconNames
} from '@common/modules/react/themes/components';
import {
  Tab as MuiTab,
  styled,
  type TabTypeMap as MuiTabTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTabCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  iconName?: IconNames;
};

type PropsToOmit = 'centerRipple' |
 'disableFocusRipple' |
 'disableRipple' |
 'disableTouchRipple' |
 'focusRipple' |
 'TouchRippleProps' |
 'touchRippleRef';

export type AxTabTypeMap<P = object, D extends React.ElementType = 'div'> = {
  props: P & AxTabCustomProps & Omit<MuiTabTypeMap<P, D>['props'], PropsToOmit>;
  defaultComponent: D;
};

export type AxTabProps<
  D extends ElementType = AxTabTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTabTypeMap<P & AxTabCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTab'
};

const StyledAxTab = styled(MuiTab, styledOptions)<AxTabProps>((props) => {

  const {
    theme
  } = props;

  const hoverStyles = {
    background: theme.uiKit.tabs.hover.background
  };
  
  return {
    opacity: 1, // override default opacity of 0.6
    fontSize: theme.uiKit.tabs.fontSize,
    lineHeight: theme.uiKit.tabs.lineHeight,
    color: theme.uiKit.tabs.color,
    transition: theme.transitions.create('box-shadow', {
      duration: theme.uiKit.transitionSpeed
    }),
    height: theme.uiKit.tabs.height,
    minHeight: theme.uiKit.tabs.height,
    fontWeight: theme.uiKit.tabs.fontWeight,
    '&:hover': {
      ...hoverStyles
    },
    '&.Mui-selected': {
      color: theme.uiKit.tabs.selected.color
    },
    '&.Mui-focusVisible': {
      ...theme.mixins.componentKeyboardFocusStyles('inset')
    }
  };
});

export const AxTab: MuiOverridableComponent<AxTabTypeMap> = forwardRef(({
  iconPosition = 'start',
  iconName,
  icon,
  ...otherProps
}: AxTabProps, ref: Ref<HTMLDivElement>) => {

  const tabProps: AxTabProps = {
    ...otherProps
  };

  const iconElement = iconName
    ? <AxIcon iconName={ iconName } />
    : icon;

  return (
    <StyledAxTab
      iconPosition={ iconPosition }
      { ...tabProps }
      ref={ ref }
      icon={ iconElement }
    />
  );
});

export default AxTab;
