const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const MetadataStringHelper = require('@common/components/cardMetadata/MetadataStringHelper');

const LearningObjectiveCardLayoutView = require('@common/components/learningObjectives/LearningObjectiveCardLayoutView');
const LearningObjectiveCardActionView = require('@common/components/learningObjectives/LearningObjectiveCardActionView');
const AssessmentCardGraphicView = require('@common/components/learningObjectives/AssessmentCardGraphicView');
const AssessmentCardTagView = require('@common/components/learningObjectives/AssessmentCardTagView');
const AssessmentCardMetaDataView = require('@common/components/learningObjectives/AssessmentCardMetaDataView');

const AssessmentType = require('@common/data/enums/AssessmentType');
const LearningObjectiveCardActionType = require('@common/components/learningObjectives/LearningObjectiveCardActionType');

const _getGraphicRegionDefinition = function(assessmentType) {
  return {
    viewDefinition: {
      ViewClass: AssessmentCardGraphicView,
      assessmentType
    }
  };
};

const _getTagRegionDefinition = function(assessmentType) {
  return {
    viewDefinition: {
      ViewClass: AssessmentCardTagView,
      assessmentType
    }
  };
};

const _getMetaDataRegionDefinition = function({
  assessmentType,
  numberOfQuestions,
  rewardPoints,
  prereqTopic,
  isLocked,
  isOverdue,
  daysUntilDue,
  lastRelevantResult,
  passingGrade,
  pointsEarnable,
  attemptsRemaining,
  module,
  isCompletedFastTrack
} = {}) {
  return {
    viewDefinition: {
      ViewClass: AssessmentCardMetaDataView,
      assessmentType,
      numberOfQuestions,
      rewardPoints,
      prereqTopic,
      isLocked,
      isOverdue,
      daysUntilDue,
      lastRelevantResult,
      passingGrade,
      pointsEarnable,
      attemptsRemaining,
      module,
      isCompletedFastTrack
    }
  };
};

const _getActionRegionDefinition = function({
  actionType,
  model,
  isFormalExam,
  assessmentName,
  level
} = {}) {

  const isFastTrackEligible = model.get('fastTrackEligible') ?? false;

  return {
    viewDefinition: {
      ViewClass: LearningObjectiveCardActionView,
      startObjectiveCallback: (fastTrack = false) => {
        if (fastTrack) {
          model.set('fastTrack', true);
        }
        model.trigger('start:training', model);
      },
      actionType,
      name: isFormalExam ? assessmentName : I18n.t('assessments.item.title', {
        assessmentName,
        level
      }),
      programId: model.get('programId'),
      isSelectableTraining: true,
      isFastTrackEligible
    }
  };
};

const getActionType = function({
  assessmentType,
  isLocked,
  attemptsRemaining,
  inProgressAssessment,
  lastRelevantResult
} = {}) {

  const isFormalExam = assessmentType === AssessmentType.FormalExamTraining;

  if (lastRelevantResult != null
    && !lastRelevantResult.passed
    && (!isFormalExam || (attemptsRemaining > 0 && lastRelevantResult.completed))
    && inProgressAssessment == null) {
    return LearningObjectiveCardActionType.RETAKE;
  } else if ((isFormalExam && lastRelevantResult != null && !lastRelevantResult.completed) || inProgressAssessment != null) {
    const isFastTrack = inProgressAssessment?.get('fastTrack') ?? false;
    return isFastTrack ? LearningObjectiveCardActionType.RESUME_FAST_TRACK : LearningObjectiveCardActionType.RESUME;
  } else if ((isFormalExam && attemptsRemaining <= 0) || (lastRelevantResult != null && lastRelevantResult.passed)) {
    return LearningObjectiveCardActionType.NONE;
  } else if (isLocked) {
    return LearningObjectiveCardActionType.LOCKED;
  }

  return LearningObjectiveCardActionType.START;
};

const getSelectableAssessmentCardDefinition = (model) => {
  // Exams/Topic Training
  const assessmentType = model.getAssessmentType();
  const isFormalExam = assessmentType === AssessmentType.FormalExamTraining;
  const assessmentName = isFormalExam ? model.get('program').name : model.get('topic').name;
  const numberOfQuestions = model.get('questionCount');
  const passingGrade = model.get('passingGrade');
  const lastRelevantResult = model.get('lastRelevantResult');
  const isLocked = model.isLocked();

  // Topic Training
  const rewardPoints = model.get('pointsForPass');
  const prereqTopic = model.get('prereqTopic');
  const module = model.get('module');
  const level = model.get('level');

  // Certification Only
  const dueDate = model.get('dueDate');
  const isCertification = assessmentType === AssessmentType.CertificationTraining;
  const isOverdue = isCertification && dueDate != null ? MetadataStringHelper.getDueDateString(dueDate).isOverdue : undefined;
  const daysUntilDue = isCertification && dueDate != null ? MetadataStringHelper.getDueDateString(dueDate).daysRemaining : undefined;

  // Exam Only
  const pointsEarnable = isFormalExam ? model.get('pointsEarnable') : undefined;
  const attemptsRemaining = isFormalExam ? model.get('attemptsRemaining') : undefined;


  const inProgressAssessmentGetter = model.get('inProgressAssessmentGetter');
  const inProgressAssessment = _.isFunction(inProgressAssessmentGetter) ? inProgressAssessmentGetter() : undefined;
  const isCompletedFastTrack = model.get('isCompletedFastTrack') || false;

  const actionType = getActionType({
    assessmentType,
    isLocked,
    attemptsRemaining,
    inProgressAssessment,
    lastRelevantResult
  });

  const actionRegionDefinition = _getActionRegionDefinition({
    actionType,
    model,
    isFormalExam,
    assessmentName,
    level
  });

  const controllerDefinition = {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LearningObjectiveCardLayoutView,
      name: isFormalExam ? assessmentName : I18n.t('assessments.item.title', {
        assessmentName,
        level
      })
    },
    regionControllers: {
      graphicRegion: _getGraphicRegionDefinition(assessmentType),
      tagRegion: _getTagRegionDefinition(assessmentType),
      metaDataRegion: _getMetaDataRegionDefinition({
        assessmentType,
        numberOfQuestions,
        rewardPoints,
        prereqTopic,
        isLocked,
        isOverdue,
        daysUntilDue,
        lastRelevantResult,
        passingGrade,
        pointsEarnable,
        attemptsRemaining,
        module,
        isCompletedFastTrack
      }),
      actionRegion: actionRegionDefinition,
      actionRegionMobile: actionRegionDefinition
    }
  };

  return controllerDefinition;
};

module.exports = {
  getSelectableAssessmentCardDefinition,
  getActionType
};
