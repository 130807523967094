import {
  Check,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@common/modules/react/themes/styles/icons';
import {
  Checkbox as MuiCheckbox,
  styled,
  type CheckboxProps as MuiCheckboxProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxCheckboxProps = Omit<
  MuiCheckboxProps,
  'centerRipple' |
  'color' |
  'disableFocusRipple' |
  'disableRipple' |
  'disableTouchRipple' |
  'focusRipple' |
  'TouchRippleProps' |
  'touchRippleRef' |
  'size'
  >;

const styledOptions = {
  name: 'AxCheckbox'
};

const StyledAxCheckbox = styled(MuiCheckbox, styledOptions)(({ theme }) => {
  const defaultStyles = {
    padding: 0,
    borderRadius: theme.uiKit.borderRadiusM,
    '& input': {
      padding: 0,
      margin: 0,
      height: '100%'
    },
    '& .MuiSvgIcon-root': {
      fontSize: theme.uiKit.fontSizeS,
      width: theme.uiKit.elementSize,
      height: theme.uiKit.elementSize
    },
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed'
    },
    '&.Mui-focusVisible': theme.mixins.componentRingFocusStyles()
  };

  return defaultStyles;
});

/**
 * A wrapper around the MUI Checkbox component that applies Axonify styles.
 *
 * **💡 Note**: This component uses brand colors
 *
 * ### Links
 * - [Checkbox API](https://mui.com/material-ui/react-checkbox/)
 */
export const AxCheckbox = forwardRef((
  props
: AxCheckboxProps, ref: Ref<HTMLButtonElement>
) => {
  const CheckboxProps = {
    disableRipple: true,
    disableTouchRipple: true,
    focusRipple: false,
    disableFocusRipple: true,
    checkedIcon: <Check disabled={ props.disabled }/>,
    indeterminateIcon: <IndeterminateCheckBox disabled={ props.disabled }/>,
    icon: <CheckBoxOutlineBlank disabled={ props.disabled }/>,
    ...props
  };

  return (
    <StyledAxCheckbox { ...CheckboxProps } ref={ ref }/>
  );
});

export default AxCheckbox;
