const logging = require('logging');
const {
  defaults,
  isFunction
} = require('underscore');
const Backbone = require('Backbone');
const { sendWarnLog } = require('LoggingService');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const I18n = require('@common/libs/I18n');

const { toggleAndroidBackDisabler } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const FlowController = require('@training/apps/training/controllers/FlowController');

const TrainingModuleController = require('@training/apps/training/controllers/TrainingModuleController');
const QuestionsController = require('@training/apps/training/controllers/QuestionsController');

const AssessmentCompletionAction = require('@training/apps/training/views/assessments/AssessmentCompletionAction');
const ObjectiveResultPage = require('@training/apps/training/views/assessments/ObjectiveResultPage');
const ObjectiveResultModelFactory = require('@training/apps/training/ObjectiveResultModelFactory');

const AssessmentTitleStringFactory = require('@training/apps/training/assessmentTitle/AssessmentTitleStringFactory');
const AssessmentTypeIconFormatter = require('@training/apps/training/assessmentTitle/AssessmentTypeIconFormatter');
const AssessmentProgressControllerDefinitionFactory = require('@training/apps/training/assessmentProgress/AssessmentProgressControllerDefinitionFactory');
const AssessmentBailButtonControllerDefinitionFactory = require('@training/apps/training/assessmentBailButton/AssessmentBailButtonControllerDefinitionFactory');
const AssessmentLaunchContextFactory = require('@common/data/models/assessments/AssessmentLaunchContextFactory');
const AssessmentType = require('@common/data/enums/AssessmentType');

const PageHeaderDefinitionFactory = require('@training/widgets/pageHeader/PageHeaderDefinitionFactory');

const DefaultAssessmentPageHeaderDefinitionFactory = (assessmentType) => {
  return (options = {}) => {
    const assessmentTypeIconFormatter = AssessmentTypeIconFormatter(assessmentType);
    const headerText = I18n.t(`start.assessmentType.${ assessmentType }`)
    const headerOptions = defaults(options, {
      iconClass: options.isFastTrack ? 'icon-bolt' : assessmentTypeIconFormatter.iconClass,
      iconLabel: options.isFastTrack ? I18n.t('fastTrack.fastTrackTitle') : assessmentTypeIconFormatter.iconLabel,
      text: headerText
    });

    if (options.isFastTrack) {
      Object.assign(headerOptions, {
        text: `${ I18n.t('fastTrack.fastTrackTitle') }: ${ headerOptions.text }`
      });
    }

    return PageHeaderDefinitionFactory(headerOptions);
  };
};

class AssessmentProcessingController extends FlowController {

  constructor(parentProcessor, options = {}) {
    super(parentProcessor);

    this.processSequenceFlow = this.processSequenceFlow.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);
    this.getAssessmentResult = this.getAssessmentResult.bind(this);
    this.processAssessmentResult = this.processAssessmentResult.bind(this);
    this.pauseAssessment = this.pauseAssessment.bind(this);

    ({
      sessionModel: this.sessionModel,
      assessment: this.assessment,
      nextItemProvider: this.nextItemProvider,
      assessmentResultHandler: this.assessmentResultHandler
    } = options);

    this.isInProgress = false;

    this.assessmentType = this.assessment.getType();
    this.pageHeaderDefinitionFactory = options.pageHeaderDefinitionFactory || DefaultAssessmentPageHeaderDefinitionFactory(this.assessmentType);

    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');
  }

  appDidBecomeActive() {
    this.resumeAssessment();
  }

  getTrainingModuleController() {
    return this.trainingModuleController != null
      ? this.trainingModuleController
      : (this.trainingModuleController = new TrainingModuleController(this, this.sessionModel));
  }

  getQuestionsController() {
    return this.questionsController != null
      ? this.questionsController
      : (this.questionsController = new QuestionsController(this, this.sessionModel));
  }

  getNextActivity() {
    return this.assessment.activities.nextActivity();
  }

  processSequenceFlow() {
    logging.debug(`AssessmentProcessingController - Processing assessment sequence for type: ${ this.assessmentType }`);

    if (this.assessment != null) {
      // XXX - this is needed to ensure that the window.history is larger than 1 so our native code will evaluate
      // WebView.canGoBack() to be true. This is mainly for when the app loads fresh into an existing assessment;
      // either VideoPage or questions directly.
      Backbone.history.navigate('#!');
      Backbone.history.navigate('#index');

      if (!this.isInProgress) {
        this.startAssessment();
      }

      return;
    }

    throw new Error('You cannot call processing of an assessment without providing an assessment itself.');
  }

  startAssessment() {
    logging.debug(`AssessmentProcessingController - Starting assessment for type: ${ this.assessmentType }`);

    this._uiContextSetup();

    this.showAssessmentPageTitleProgress();
    this.showAssessmentBailButton();

    this.assessment.startTimingAssessment();

    this.processActivities();
  }

  resumeAssessment() {
    if (this.assessment === null) {
      logging.error('AssessmentProcessingController - Can\'t resume assessment; there is no current assessment.');
      return;
    }

    logging.debug(`AssessmentProcessingController - Resuming assessment for type: ${ this.assessmentType }`);
    this.assessment.restartTimer();
  }

  processActivities() {
    logging.debug(`AssessmentProcessingController - Processing assessment activities for type: ${ this.assessmentType }`);

    const activity = this.getNextActivity();

    if (activity && activity.isTrainingContentActivity()) {
      // Show the next training activity
      this.showAssessmentPageTitle();
      this.getTrainingModuleController().processActivities();
    } else if (activity && activity.isQuestionContentActivity() || this.assessment.gamePlay.isInProgress()) {
      this.getQuestionsController().processActivities();
    } else {
      this.finishAssessment().then(() => {
        this.processAssessmentResult(this.assessment);
      });
    }
  }

  finishedProcessing() {
    logging.debug(`AssessmentProcessingController - Finished processing assessment for type: ${ this.assessmentType }`);

    return this.parentProcessor.processSequenceFlow()
      .then(() => {
        logging.info('Assessment has ended. Conceded control back to intial initiator');
        this.parentProcessor.processParentFlow();
      })
      .catch(() => {
        // nom nom nom
      });
  }

  finishAssessment() {
    logging.debug(`AssessmentProcessingController - Finish assessment for type: ${ this.assessmentType }`);

    // if there are no more activities we need to save the time spent and close
    // the current assessment by calling get assessment result
    return Promise.resolve(this.saveAssessmentTimeSpent()).reflect()
      .then(() => {
        return this.getAssessmentResult().always(() => {
          logging.debug(`AssessmentProcessingController - Stop timing assessment for type: ${ this.assessmentType }`);
          this.assessment.stopTimingAssessment();
          window.apps.base.timeLogController.sendTimeLog();
        });
      });
  }

  saveAssessmentTimeSpent() {
    logging.debug(`AssessmentProcessingController - Saving assessment time spent for type: ${ this.assessmentType }`);

    const saveTimeSpentDeferred = this.assessment.pauseAndSaveTimeSpent();
    return saveTimeSpentDeferred.fail(() => {
      // An error occured in saving the time spent, continue with the flow
      logging.error(`AssessmentProcessingController - Error saving assessment time spent for type: ${ this.assessmentType }`);
    });
  }

  getAssessmentResult() {
    logging.debug(`AssessmentProcessingController - Getting assessment result for type: ${ this.assessmentType }`);

    return this.assessment.fetchAssessmentResult().then(() => {
      this.sessionModel.sendBadgeUpdate({
        showSpinner: false
      });
    });
  }

  endAssessmentProcessing() {
    logging.debug(`AssessmentProcessingController - Finalizing assessment processing for type: ${ this.assessmentType }`);

    return this.sessionModel.fetch().then(() => {
      this._uiContextCleanup();
      return this.finishedProcessing();
    });
  }

  processAssessmentResult(currentAssessment) {
    logging.info(`AssessmentProcessingController - Processing result for assessment type: ${ this.assessmentType }`);

    if (!currentAssessment.shouldShowAssessmentResults()) {
      return this.endAssessmentProcessing();
    }

    const assessmentOption = currentAssessment.getAssessmentResultOption();
    logging.debug(`AssessmentProcessingController - Assessment options before processing result: ${ assessmentOption }`);

    if (assessmentOption != null) {
      this.assignPointsForAssessment(assessmentOption);

      return this.nextItemProvider.getNext()
        .catch((error) => {
          logging.error(error);
          logging.error('There was a problem getting the next item from the provider. Showing the user results without a next item');
          return null;
        })
        .then((nextItem = null) => {
          // When launchContext isn't set, we have to make sure to set it according to its mapped assessmentType
          // This way the user's starting context is the corresponding quizboard view for next items
          if (nextItem != null && nextItem.getLaunchContext() == null) {
            nextItem.setLaunchContext(AssessmentLaunchContextFactory(nextItem.getAssessmentType()));
          }

          logging.debug(`AssessmentProcessingController - Assessment options before showing result page: ${ assessmentOption }`);
          this.showAssessmentResultPage(assessmentOption, nextItem);
        });
    }

    const data = currentAssessment.toJSON();

    sendWarnLog({
      message: `AssessmentProcessingController - Assessment option with identity data: ${ JSON.stringify(data) } was not found!`
    });

    return this.endAssessmentProcessing();
  }

  onAssessmentResultPageComplete(requestedAction, currentOption, nextItem) {
    logging.debug(`AssessmentProcessingController - Assessment result page complete for: type - ${ this.assessmentType } and action - ${ requestedAction }`);

    // In case someone requested it off
    this.getQuestionsController().toggleQuestionMode(false);

    // If the user wanted a retake or start full topic first, then process that instead of any other logic
    if (requestedAction === AssessmentCompletionAction.Retake || requestedAction === AssessmentCompletionAction.StartFullTopic) {
      this.startAssessmentCreation(currentOption, true);
    } else if (requestedAction === AssessmentCompletionAction.YieldControl) {
      this.endAssessmentProcessing();
    } else if (requestedAction === AssessmentCompletionAction.StartNext || requestedAction === AssessmentCompletionAction.FastTrack) {
      if (requestedAction === AssessmentCompletionAction.FastTrack) {
        nextItem.set('fastTrack', true);
      }
      this.startAssessmentCreation(nextItem, false);
    }
  }

  startAssessmentCreation(assessmentOption, isCurrentAssessmentRetakeOrStartFullTopic = false) {
    logging.debug(`AssessmentProcessingController - Starting assessment creation for assessment of type: ${ assessmentOption.getAssessmentType() }`);
    // `isCurrentAssessmentRetakeOrStartFullTopic` is needed to initiate with the `DeepLinkingAssessmentInitiator` since the default
    // functionality is to find an assessment initiator based on the Training Session Type but in the "retake" or "start full topic" case we
    // don't want to change the initiator from `DeepLinkingAssessmentInitiator`
    if (isCurrentAssessmentRetakeOrStartFullTopic) {
      this.parentProcessor.createAssessment(assessmentOption)
        .then(({
          assessment,
          context
        }) => {
          this.isInProgress = false;
          return this.parentProcessor.processAssessment(assessment, context);
        })
        .catch(() => {
          this.endAssessmentProcessing();
        });
    } else {
      this.isInProgress = false;
      this.parentProcessor.processTraining(assessmentOption)
        .catch(() => {
          this.endAssessmentProcessing();
        });
    }
  }

  pauseAssessment() {
    // When the user attempts to bail after answering the final question of an assessment, there is no need to execute
    // any of the logic below - the assessment is considered completed
    if (this.getNextActivity() == null) {
      // We do not wish to show the assessment result page a second time if the assessmentResult has already been
      // processed. This only happens when a user deep links to a new assessment from an assessment's results page
      if (!this.assessment.hasAssessmentResult()) {
        return Promise.resolve(this.finishAssessment())
          .then(() => {
            return Promise.resolve(this.processAssessmentResult(this.assessment));
          })
          .then(() => {
            return Promise.resolve({ isShowingResultsPage: true });
          });
      }

      this._uiContextCleanup();
      return Promise.resolve();
    }

    this.assessment.activities.stopInProgressCompletedActivityTimer();

    return Promise.resolve(this.assessment.stopGamePlay()).reflect()
      .then(() => {
        return Promise.resolve(this.saveAssessmentTimeSpent()).reflect();
      })
      .then(() => {
        logging.debug(`AssessmentProcessingController - Stop timing assessment for type: ${ this.assessmentType }`);
        this.assessment.stopTimingAssessment();
        window.apps.base.timeLogController.sendTimeLog();
        return Promise.resolve(this.sessionModel.pauseAssessment());
      })
      .catch((xhr) => {
        window.app.layout.flash.error(I18n.t('assessments.bail.error'));
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        logging.error(exception.getErrorMessage());
      })
      .then(() => {
        logging.debug(`AssessmentProcessingController - Pausing Assessment: type - ${ this.assessmentType }`);

        this.getQuestionsController().toggleQuestionMode(false);

        return Promise.resolve(this.sessionModel.fetch());
      })
      .then(() => {
        this._uiContextCleanup();
      });
  }

  assignPointsForAssessment(assessmentOption) {
    logging.debug(`AssessmentProcessingController - Assigning points for assessment type: ${ this.assessmentType }`);
    if (assessmentOption.hasPassedLastAttempt()) {
      const points = assessmentOption.get('pointsForPass');
      window.apps.auth.session.user.addPoints(points);
    }
  }

  showAssessmentResultPage(assessmentOption, nextItem) {
    logging.debug(`AssessmentProcessingController - Showing assessment result page for type: ${ this.assessmentType }`);
    logging.debug(`AssessmentProcessingController - Result page initialized with assessmentOption contiaining type: ${ assessmentOption.getAssessmentType() }`);

    const objectiveResult = ObjectiveResultModelFactory.fromAssessmentTopicOption(assessmentOption);
    let objectiveResultPage;
    const resultContext = {
      doneAssessmentProcessing: true
    }

    if (isFunction(this.assessmentResultHandler)) {
      objectiveResultPage = this.assessmentResultHandler(objectiveResult, assessmentOption, nextItem, resultContext, this.onAssessmentResultPageComplete.bind(this));

    } else {
      const canGoBackAfterCompletion = this.nextItemProvider.canProduceNext();
      const sessionTrainingType = canGoBackAfterCompletion
        ? this.parentProcessor.getSessionTrainingType() // only generates a value if the user can go back
        : undefined;

      objectiveResultPage = new ObjectiveResultPage({
        model: objectiveResult,
        resultItem: assessmentOption,
        upcomingItem: nextItem,
        canGoBackAfterCompletion,
        sessionTrainingType,
        complete: (requestedAction) => {
          const isAssessmentRetake = requestedAction === AssessmentCompletionAction.Retake && AssessmentType.supportsQuestions(assessmentOption.getForAssessmentType());
          const isAssessmentStartNext = nextItem != null && requestedAction === AssessmentCompletionAction.StartNext && AssessmentType.supportsQuestions(nextItem.getForAssessmentType());
          const isAssessmentFastTrackOrStartFullTopic = requestedAction === AssessmentCompletionAction.StartFullTopic || requestedAction === AssessmentCompletionAction.FastTrack;
          resultContext.doneAssessmentProcessing = !isAssessmentStartNext && !isAssessmentRetake && !isAssessmentFastTrackOrStartFullTopic;

          this.onAssessmentResultPageComplete(requestedAction, assessmentOption, nextItem);
        }
      });
    }


    const headerDefinition = this.pageHeaderDefinitionFactory({ isFastTrack: false });

    objectiveResultPage.listenTo(objectiveResultPage, 'destroy', () => {
      if (resultContext.doneAssessmentProcessing) {
        this._uiContextCleanup();
      }
    });

    window.app.layout.setPageTitleBarLeftDefinition(headerDefinition);
    window.app.layout.setPageTitleBarBailDefinition();
    window.app.layout.setView(objectiveResultPage);
  }

  showAssessmentPageTitle() {
    const headerDefinition = this.pageHeaderDefinitionFactory({
      isFastTrack: this.assessment.isFastTrack(),
      text: AssessmentTitleStringFactory(this.assessment)
    });

    window.app.layout.setPageTitleBarLeftDefinition(headerDefinition);
  }

  showAssessmentPageTitleProgress() {
    const progressDefinition = AssessmentProgressControllerDefinitionFactory(this.assessment);

    window.app.layout.setPageTitleBarRightDefinition(progressDefinition);
  }

  showAssessmentBailButton() {
    const bailDefinition = AssessmentBailButtonControllerDefinitionFactory(() => {
      this.pauseAssessment().then((processingConfig = {}) => {
        // In the case where the assessment result page is showing after a bail, we need to make sure we don't finish
        // processing. If we were to finish processing, we would yield control to the parent processor and this could
        // cause the results page to be bypassed entirely.
        if (!processingConfig.isShowingResultsPage) {
          this.finishedProcessing();
        }
      });
    });

    window.app.layout.setPageTitleBarBailDefinition(bailDefinition);
  }

  _uiContextSetup() {
    this.isInProgress = true;

    toggleAndroidBackDisabler(true);

    window.app.layout.toggleFullScreen(true);
    window.app.layout.togglePageHeader(true);

    // Commentting this out for now as we'll want to restore this functionality later on
    // once we figure out how to create a leave assessment hook.
    // window.app.layout.toggleMenuMinimalDisplay(true);
    window.app.layout.toggleMenuHiddenDisplay(true);

    this.listenTo(this.nbChannel.vent, 'appDidBecomeActive', this.appDidBecomeActive);

    window.app.layout.showEmptyView();
  }

  _uiContextCleanup() {
    this.stopListening();

    window.app.layout.toggleFullScreen(false);
    window.app.layout.togglePageHeader(false);
    // window.app.layout.toggleMenuMinimalDisplay(false);
    window.app.layout.toggleMenuHiddenDisplay(false);

    toggleAndroidBackDisabler(false);

    this.isInProgress = false;

    window.app.layout.showEmptyView();
  }
}

module.exports = AssessmentProcessingController;
