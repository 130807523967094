import { type QAClassName } from '@common/interfaces/SharedTypes';
import { generateId } from '@common/libs/helpers';
import UrlHelpers from '@common/libs/helpers/app/UrlHelpers';
import { AxLink } from '@common/modules/react/themes/components';
import {
  Fragment,
  forwardRef,
  isValidElement,
  useCallback,
  useMemo,
  type ComponentProps,
  type ReactNode,
  type Ref
} from 'react';

export type LinkifyProps = {
  linkRegex?: RegExp;
  children?: string | string[];
  renderLink?: (link: string) => JSX.Element | undefined;
  linkProps?: Omit<ComponentProps<typeof AxLink>, 'children' | 'qaClassName'>;
  qaClassName: QAClassName;
};

/**
 * Component to convert links URLs in a string to clickable elements
 *
 * @param props.children - The text to convert
* @param props.renderLink - Method to render the link, if not provided, a default link will be rendered
 * @param props.linkProps - The props to pass to the link component (not available if you pass a render function)
 *
 * @example
 * Usage:
 * <Linkify qaClassName='qa-link'>This is a link: https://www.google.com</Linkify>
 *
 * <Linkify
 *  linkProps={{
 *   sx: { background: 'red' },
 *   onClick: () => { console.log('clicked') }
 *  }}
 *  qaClassName='qa-link'
 * >
 *   This is a link: https://www.google.com
 *  </Linkify>
 *
 * <Linkify renderLink={ (url: string) => {
 *  return <AxButton href={ url } variant='ghost'>{ url }</AxButton>;
 * }}
 * qaClassName='qa-link'>This is a link: https://www.google.com</Linkify>
 *
*/
const Linkify = forwardRef((props: LinkifyProps, ref: Ref<HTMLAnchorElement>) => {
  const {
    children,
    linkRegex = UrlHelpers.URL_REGEX,
    renderLink,
    qaClassName,
    linkProps
  } = props;

  const renderLinkFn: (link: string) => JSX.Element = useCallback((link: string) => {
    const key = generateId();
    const href = !link.startsWith('http') ? `https://${ link }` : link;
    const target = UrlHelpers.isExternalLink(href) ? '_blank' : '_self';

    if (renderLink) {
      const element = renderLink(href);
      if (isValidElement(element)) {
        return <Fragment key={ key }>{ element }</Fragment>;
      }
    }

    const baseLinkProps = {
      target,
      rel: 'noreferrer',
      href,
      key,
      ref,
      qaClassName
    };
    const passedProps = {
      ...baseLinkProps,
      ...linkProps
    };
    return <AxLink { ...passedProps }>{ link }</AxLink>;
  }, [linkProps, ref, renderLink, qaClassName]);

  const parseString: (str: string) => ReactNode[] | string = useCallback((str: string) => {
    let match: RegExpExecArray | null;
    let rest = str;
    const elements: ReactNode[] = [];

    while ((match = linkRegex.exec(rest)) !== null) {
      if (match[0] === undefined) {
        break;
      }
      const urlStartIndex = match.index;
      const urlEndIndex = match.index + match[0].length;
      const textBeforeMatch = rest.slice(0, urlStartIndex);
      const url = rest.slice(urlStartIndex, urlEndIndex);
      rest = rest.slice(urlEndIndex);

      if (textBeforeMatch !== '') {
        elements.push(textBeforeMatch);
      }

      elements.push(renderLinkFn(url));
    }

    let content: string | ReactNode[] = elements;

    if (elements.length === 0) {
      // No urls present so returning text
      content = str;
    } else if (rest.length > 0) {
      // Appends remaning text after link if any exists
      elements.push(<Fragment key={ generateId() }>{ rest }</Fragment>);
      content = elements;
    }
    return content;

  }, [linkRegex, renderLinkFn]);

  const content = useMemo(() => {
    if (!children) {
      return null;
    }
    let text;
    if (Array.isArray(children)) {
      text = children.join('');
    } else {
      text = children;
    }
    return parseString(text);
  }, [children, parseString]);

  return (
    <>{ content }</>
  );
});

export default Linkify;

