const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const $os = require('detectOS');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const AssessmentType = require('@common/data/enums/AssessmentType');

const DAYS_DUE_WARNING_THRESHOLD = 5;

class AssessmentCardMetaDataView extends ItemView {
  className() {
    return 'assessment-card__meta-data';
  }

  tagName() {
    return 'div';
  }

  getTemplate() {
    return `
      <% if (isLocked && prereqTopic) { %>
        <span><%- t('assessments.item.passToUnlock', { topic: prereqTopic.name }) %></span>
      <% } %>

      <% if (topicStatusText) { %>
        <div class="topic-status">
          <span class="topic-status__icon <%- topicStatusIconClass %>" aria-hidden="true"></span>
          <span class="topic-status__description <%- topicStatusCssClass %>"><%- topicStatusText %></span>
        </div>
      <% } %>

      <% if (topicStatusText && lastAttemptResultsText) { %>
        <span><%- lastAttemptResultsText %></span>
      <% } %>

      <% if (!isLocked && !topicStatusText && !lastAttemptResultsText && numberOfModulesText) { %>
        <span><%- numberOfModulesText %></span>
      <% } %>

      <% if (!isLocked && !lastAttemptResultsText && numberOfQuestionsText) { %>
        <span><%- numberOfQuestionsText %></span>
      <% } %>

      <% if (rewardPointsText) { %>
        <span class="meta__reward-points <%- rewardPointsCssClass %>"><%- rewardPointsText %></span>
      <% } %>

      <% if (examAttemptsText) { %>
        <span><%- examAttemptsText %></span>
      <% } %>

      <% if (!isLocked && certStatusText) { %>
        <span class="<%- certStatusCssClass %>"><%- certStatusText %></span>
      <% } %>
    `;
  }

  templateHelpers() {
    /* Examples for reference

      * numberOfQuestionsText: '6 questions'
      * rewardPointsText:
        - On completion: '+16 points earned'
        - No prior exam attempts: 'Up to 16 reward points'
      * certStatusText: 'Due in 19 days'
      * topicStatusText: 'Passed - 100%'
      * examAttemptsText: '3 attempts remaining'
      * lastAttemptResultsText: '5 of 12 correct'

    */

    return {
      numberOfModulesText: AssessmentCardMetaDataView._getNumberOfModulesText(this.getOption('module')),
      numberOfQuestionsText: AssessmentCardMetaDataView._getNumberOfQuestionsText(this.getOption('numberOfQuestions')),
      rewardPointsText: AssessmentCardMetaDataView._getRewardPointsText({
        assessmentType: this.getOption('assessmentType'),
        rewardPoints: this.getOption('rewardPoints'),
        pointsEarnable: this.getOption('pointsEarnable'),
        lastRelevantResult: this.getOption('lastRelevantResult')
      }),
      rewardPointsCssClass: AssessmentCardMetaDataView._getRewardPointsCssClass({
        assessmentType: this.getOption('assessmentType'),
        lastRelevantResult: this.getOption('lastRelevantResult'),
        pointsEarnable: this.getOption('pointsEarnable')
      }),
      certStatusText: AssessmentCardMetaDataView._getCertificationStatusMessage({
        assessmentType: this.getOption('assessmentType'),
        isOverdue: this.getOption('isOverdue'),
        daysUntilDue: this.getOption('daysUntilDue')
      }),
      certStatusCssClass: AssessmentCardMetaDataView._getCertificationStatusCssClass({
        assessmentType: this.getOption('assessmentType'),
        daysUntilDue: this.getOption('daysUntilDue')
      }),
      topicStatusText: AssessmentCardMetaDataView._getTopicStatusText({
        assessmentType: this.getOption('assessmentType'),
        attemptsRemaining: this.getOption('attemptsRemaining'),
        lastRelevantResult: this.getOption('lastRelevantResult'),
        passingGrade: this.getOption('passingGrade'),
        isCompletedFastTrack: this.getOption('isCompletedFastTrack')
      }),
      topicStatusCssClass: AssessmentCardMetaDataView._getTopicStatusCssClass(
        this.getOption('lastRelevantResult'),
        this.getOption('assessmentType')
      ),
      topicStatusIconClass: AssessmentCardMetaDataView._getTopicStatusIconClass(
        this.getOption('lastRelevantResult'),
        this.getOption('assessmentType')
      ),
      examAttemptsText: AssessmentCardMetaDataView._getExamAttemptsText({
        assessmentType: this.getOption('assessmentType'),
        attemptsRemaining: this.getOption('attemptsRemaining'),
        lastRelevantResult: this.getOption('lastRelevantResult')
      }),
      lastAttemptResultsText: AssessmentCardMetaDataView._getLastAttemptResultsText(
        this.getOption('lastRelevantResult'),
        this.getOption('assessmentType')
      ),
      prereqTopic: this.getOption('prereqTopic'),
      isLocked: this.getOption('isLocked')
    };
  }


  static _getNumberOfModulesText(module) {
    if (module != null) {
      return I18n.t(`assessments.item.content.training.${ module.moduleType }`);
    }
    return undefined;
  }

  static _getNumberOfQuestionsText(numberOfQuestions) {
    if (numberOfQuestions === 1) {
      return I18n.t('assessments.progress.segment.questions.singular');
    } else if (numberOfQuestions > 1) {
      return I18n.t('assessments.progress.segment.questions.plural', {count: numberOfQuestions});
    }

    return undefined;
  }

  static _getRewardPointsText({
    assessmentType,
    rewardPoints,
    pointsEarnable,
    lastRelevantResult
  }) {
    const isFormalExam = assessmentType === AssessmentType.FormalExamTraining;
    const hasAttempted = lastRelevantResult != null;
    const hasPassed = hasAttempted ? lastRelevantResult.passed : undefined;

    let assessmentPointsEarned;

    if (hasAttempted && isFormalExam) {
      assessmentPointsEarned = lastRelevantResult.pointsEarned;
    } else if (hasAttempted) {
      assessmentPointsEarned = rewardPoints;
    }

    if (!hasAttempted && !isFormalExam) {
      return undefined;
    } else if (!$os.mobile && !hasAttempted && pointsEarnable > 0) {
      return I18n.t('assessments.item.rewardPoints.totalPointsEarnable', { total: pointsEarnable });
    } else if ($os.mobile && hasAttempted && hasPassed && assessmentPointsEarned > 0) {
      return I18n.t('assessments.item.rewardPoints.assessmentEarned_short', {
        points: isFormalExam ? lastRelevantResult.pointsEarned : rewardPoints
      });
    } else if (hasAttempted && hasPassed && assessmentPointsEarned > 0) {
      return I18n.t('assessments.item.rewardPoints.assessmentEarned', {
        points: isFormalExam ? lastRelevantResult.pointsEarned : rewardPoints
      });
    }

    return undefined;
  }

  static _getRewardPointsCssClass({
    assessmentType,
    lastRelevantResult,
    pointsEarnable
  }) {
    const isFormalExam = assessmentType === AssessmentType.FormalExamTraining;
    const hasAttempted = lastRelevantResult != null;

    if (!$os.mobile && !hasAttempted && isFormalExam && pointsEarnable > 0) {
      return '';
    }
    return 'reward-points__points-earned';
  }


  static _getCertificationStatusMessage({
    assessmentType,
    isOverdue,
    daysUntilDue
  }) {
    if (assessmentType !== AssessmentType.CertificationTraining) {
      return undefined;
    }

    let message;

    if (isOverdue) {
      message = I18n.t('GuidedLearning.statusHeader.overdue');
    } else if (daysUntilDue === 1) {
      message = I18n.t('GuidedLearning.statusHeader.daysAwayHeaderSingular');
    } else if (daysUntilDue === 0) {
      message = I18n.t('GuidedLearning.statusHeader.dueToday');
    } else if (daysUntilDue) {
      message = I18n.t('GuidedLearning.statusHeader.daysAwayHeader', { daysRemaining: daysUntilDue });
    }

    return message;
  }

  static _getCertificationStatusCssClass({
    assessmentType,
    daysUntilDue
  }) {
    return (assessmentType === AssessmentType.CertificationTraining && daysUntilDue <= DAYS_DUE_WARNING_THRESHOLD)
      ? 'learning-objective-card__cert-status--warning' : '';
  }

  static _getTopicStatusText({
    assessmentType,
    attemptsRemaining,
    lastRelevantResult,
    passingGrade,
    isCompletedFastTrack
  } = {}) {
    // Server provides a populated lastRelevantResult even when the assessment hasn't been completed for Formal Exams only
    if (lastRelevantResult == null
      || AssessmentCardMetaDataView._isFormalExamInProgress(lastRelevantResult, assessmentType)) {
      return undefined;
    }

    const isFormalExam = assessmentType === AssessmentType.FormalExamTraining;

    const requiredScore = passingGrade != null
      ? passingGrade
      : TenantPropertyProvider.get().getProperty('introTrainingPassingGrade');

    const grade = lastRelevantResult.score != null
      ? lastRelevantResult.score
      : Math.round((lastRelevantResult.correctAnswerCount / Math.max(1, lastRelevantResult.totalAnswerCount)) * 100);

    if (lastRelevantResult.passed) {
      if (lastRelevantResult.correctAnswerCount === 0) {
        return I18n.t('assessments.item.grade.text.pass');
      } else if (isCompletedFastTrack) {
        return I18n.t('assessments.item.status.passedFastTrack', { grade: grade });
      }
      return I18n.t('assessments.item.status.passed', { grade: grade });
    } else if ($os.mobile && (!isFormalExam || isFormalExam && attemptsRemaining > 0)) {
      return I18n.t('assessments.item.status.notPassed_short', { grade: grade });
    }

    return I18n.t('assessments.item.status.notPassed', {
      grade: grade,
      passingGrade: requiredScore
    });
  }

  static _getTopicStatusCssClass(lastRelevantResult, assessmentType) {
    // Server provides a populated lastRelevantResult even when the assessment hasn't been completed for Formal Exams only
    if (lastRelevantResult == null
      || lastRelevantResult.passed
      || AssessmentCardMetaDataView._isFormalExamInProgress(lastRelevantResult, assessmentType)) {
      return '';
    }
    return 'topic-status--failed';
  }

  static _getTopicStatusIconClass(lastRelevantResult, assessmentType) {
    // Server provides a populated lastRelevantResult even when the assessment hasn't been completed for Formal Exams only
    if (lastRelevantResult == null
      || AssessmentCardMetaDataView._isFormalExamInProgress(lastRelevantResult, assessmentType)) {
      return '';
    }

    if (lastRelevantResult.passed) {
      return 'icon-ok_circle';
    }
    return 'icon-remove_circle';
  }

  static _getExamAttemptsText({
    assessmentType,
    attemptsRemaining,
    lastRelevantResult
  }) {
    if (assessmentType !== AssessmentType.FormalExamTraining || (lastRelevantResult != null && lastRelevantResult.passed)) {
      return undefined;
    }

    if (attemptsRemaining === 1) {
      return I18n.t('assessments.item.attemptsRemaining.singular');
    }
    return I18n.t('assessments.item.attemptsRemaining.plural', { count: attemptsRemaining });
  }

  static _getLastAttemptResultsText(lastRelevantResult, assessmentType) {
    // Server provides a populated lastRelevantResult even when the assessment hasn't been completed for Formal Exams only
    if (lastRelevantResult == null
      || AssessmentCardMetaDataView._isFormalExamInProgress(lastRelevantResult, assessmentType)) {
      return undefined;
    }

    return lastRelevantResult.totalAnswerCount === 0 ? undefined : I18n.t('assessments.item.lastScore', {
      correct: lastRelevantResult.correctAnswerCount,
      total: lastRelevantResult.totalAnswerCount
    });
  }

  static _isFormalExamInProgress(lastRelevantResult = {}, assessmentType) {
    return !lastRelevantResult.completed && assessmentType === AssessmentType.FormalExamTraining;
  }
}

module.exports = AssessmentCardMetaDataView;
