const _ = require('underscore');
const Backbone = require('Backbone');

const GuidedLearningObjectiveList = require('@common/components/guidedLearning/collections/GuidedLearningObjectiveList');
const MilestoneInterfaceList = require('@common/components/guidedLearning/collections/MilestoneInterfaceList');

const {
  inflateListBasedOnPrerequisiteId,
  decorateWithInProgressAssessments
} = require('./GuidedLearningCategoryHelpers');

// This is not to be confused with a content category, these are two different concepts. This is just a way
// of generate a hierarchy of nodes which eventually the leaf nodes will contain objectives.

const LEARNING_CATEGORY_FIELD_NAME = 'name';
const OBJECTIVES_FIELD_NAME = 'objectives';
const SUB_CATEGORIES_FIELD_NAME = 'subCategories';
const OBJECTIVES_COMPLETED_COMPUTED = 'completedCount';
const OBJECTIVES_TOTAL_COMPUTED = 'count';
const OBJECTIVES_AVAILABLE = 'currentItems';
const OBJECTIVES_NEXT = 'lowerPriorityItems';
const OBJECTIVES_PROGRESS_FIELD_NAME = 'progressToCompletion';
const AVAILABLE_START_FIELD_NAME = 'availableStart';
const STAY_ON_TRACK_NUMBER = 'stayOnTrackNumber';
const PROGRAM_ID = 'id';
const THUMBNAIL_IMAGE = 'thumbnailImage';
const DESCRIPTION = 'description';
const ENROLLED = 'enrolled';
const MANAGER_ASSIGNED = 'isManagerAssigned';

class GuidedLearningCategory extends Backbone.Model {
  preinitialize(attrs, options = {}) {
    ({
      userId: this.userId,
      programId: this.programId,
      sessionModel: this.sessionModel
    } = options);
  }

  apiEndpoint() {
    return `/assignedProgram/available/program/${ this.programId && this.userId ? `${ this.programId }/${ this.userId }` : '' }`;
  }

  parse(response = {}) {
    const currentItems = response[OBJECTIVES_AVAILABLE] || [];

    // populate current items with any in progress assessments
    decorateWithInProgressAssessments(currentItems, this.sessionModel);
    response[OBJECTIVES_AVAILABLE] = inflateListBasedOnPrerequisiteId(currentItems);
    return response;
  }

  defaults() {
    const defaults = {};

    defaults[OBJECTIVES_FIELD_NAME] = [];
    defaults[OBJECTIVES_AVAILABLE] = [];
    defaults[OBJECTIVES_NEXT] = [];

    defaults[SUB_CATEGORIES_FIELD_NAME] = [];
    defaults[OBJECTIVES_TOTAL_COMPUTED] = 0;
    defaults[OBJECTIVES_COMPLETED_COMPUTED] = 0;
    return defaults;
  }

  get stayOnTrackNumber() {
    return this.get(STAY_ON_TRACK_NUMBER) || 0;
  }

  getComputedAvailableCount() {
    return this.get(OBJECTIVES_TOTAL_COMPUTED);
  }

  getComputedCompletedCount() {
    return this.get(OBJECTIVES_COMPLETED_COMPUTED);
  }

  addObjective(objective) {
    return this.getObjectives().push(objective);
  }

  addCategory(category) {
    return this.getSubCategories().push(category);
  }

  isCompleted() {
    return this.getTotalObjectiveProgress() >= 100;
  }

  getTotalObjectiveProgress() {
    return this.get(OBJECTIVES_PROGRESS_FIELD_NAME) || 0;
  }

  getAvailableCount() {
    return this.getAllObjectives().length;
  }

  getCompletedCount() {
    const allObjectives = this.getAllObjectives();
    const completed = _(allObjectives).filter((objective) => {
      return objective.isComplete();
    });
    return completed.length;
  }

  getName() {
    return this.get(LEARNING_CATEGORY_FIELD_NAME);
  }

  getSubCategories() {
    return this.get(SUB_CATEGORIES_FIELD_NAME);
  }

  // Alias for the available items
  getObjectives() {
    return this.get(OBJECTIVES_AVAILABLE);
  }

  getSortedObjectives(milestoneFactory) {
    const items = this.get(OBJECTIVES_AVAILABLE);
    let curMilestoneItems = []; //items
    let itemsAfterLinkedMilestoneList = null;
    const glItems = {
      milestones: new MilestoneInterfaceList(),
      events: [],
      nonMilestoneObjectives: [],
      firstIncompleteMilestone: null
    };

    if (!(items instanceof Array)) {
      return glItems;
    }

    /*
     * items --> is a list of groups of guided learning items
     * group of guided learning items --> list of task/topic/certification that are linked (prerequisite chain)
     * A solo Item on the page is a group of one guided learning item
     */

    for (const item of items) {
      let currLinkedItemsGroup = []; //The linked items in this group that have been encountered so far since last milestone in the group
      if (this.isEventTraining(item[0])) { // Events check
        glItems.events.push(item);
      } else {
        let newMilestoneItem; // The previous milestone hit

        for (let i = 0; i < item.length; i++) { // loop through linked items
          if (this.isMilestone(item[i])) { // When you hit a milestone in linked set
            if (currLinkedItemsGroup.length !== 0) {
              curMilestoneItems.push(currLinkedItemsGroup); // push linked items so far in this group to prerequisites for this milestone
            }
            newMilestoneItem = milestoneFactory.createMilestoneInterface(curMilestoneItems, item[i]);
            glItems.milestones.add(newMilestoneItem);

            curMilestoneItems = new Array(); // reset the linked items encountered so far
            currLinkedItemsGroup = new Array(); // reset the prereques for next milestone
            itemsAfterLinkedMilestoneList = null; // set the items you have seen after a milestone in same linked group to null

            if (!newMilestoneItem.milestoneObjective.complete && glItems.firstIncompleteMilestone == null) { // check if this milestone is the first incomplete milestone
              glItems.firstIncompleteMilestone = newMilestoneItem.getMilestoneRowViewModel();
            }
          } else {
            currLinkedItemsGroup.push(item[i]);
          } // if the current linked item is not a milestone add list of prerequisite groups for next milestone
        }

        // For updating possible items that are after the last milestone, but linked to the last milestone
        if (this.hasLinkedItemsLeft(currLinkedItemsGroup)) { // are there items left in linked group
          if (newMilestoneItem != null && newMilestoneItem.milestoneObjective.prerequisiteId != null) { // was the last milestone seen linked to anything
            itemsAfterLinkedMilestoneList = currLinkedItemsGroup.slice(); //copy the array
          }
          curMilestoneItems.push(currLinkedItemsGroup);
        } // push items after milestone in the linked item list
      }
    }

    // If you have items after the last milestone that are linked to the last milestone
    if (itemsAfterLinkedMilestoneList != null) {
      glItems.milestones.add(milestoneFactory.createMilestoneInterface([itemsAfterLinkedMilestoneList], {}));
      curMilestoneItems = curMilestoneItems.slice(1); // remove the item we just added as a new "MilestoneItem"
    }
    glItems.nonMilestoneObjectives = curMilestoneItems;
    return glItems;
  }

  getEnrollmentStatus() {
    const enrollmentStatus = this.get(ENROLLED);

    // If enrollmentStatus is undefined (because it's a Guided Learning program)
    // then we want the status to behave as if it were enrolled.
    if (enrollmentStatus === undefined) {
      return true;
    }

    return enrollmentStatus;
  }

  isEventTraining(item) {
    return !item.groupId && !item.milestone;
  }

  isMilestone(item) {
    return item.milestone;
  }

  hasLinkedItemsLeft(list) {
    return list.length > 0;
  }

  getAvailableObjectives() {
    return _.map(this.getObjectives(), (objective) => {
      return _.reject(objective, (item) => {
        return item.milestone;
      });
    });
  }

  getProgramId() {
    return this.get(PROGRAM_ID);
  }

  getUnavailableObjectives() {
    return _.reject(this.get(OBJECTIVES_NEXT), (item) => {
      return item.milestone;
    });
  }

  hasStartableItems() {
    return this.get(AVAILABLE_START_FIELD_NAME) > 0;
  }

  // This will return all objectives, even the ones that are nested in lower levels
  getAllObjectives() {
    const myObjectives = this.getObjectives();
    const otherObjectives = [];
    const recurse = (categories, arr) => {
      return _(categories).each((category) => {
        arr.push(category.getObjectives());
        return recurse(category.getSubCategories(), arr);
      });
    };

    recurse(this.getSubCategories(), otherObjectives);
    return myObjectives.concat(_.flatten(otherObjectives));
  }

  findMatchingObjective(criteria = {}) {
    return (new GuidedLearningObjectiveList(_.flatten(this.getAvailableObjectives())).findWhere(criteria));
  }

  getThumbnailImage() {
    return this.get(THUMBNAIL_IMAGE);
  }

  getDescription() {
    return this.get(DESCRIPTION);
  }

  getManagerAssigned() {
    return this.get(MANAGER_ASSIGNED);
  }

  hasFastTrackObjective() {
    const objectives = _.flatten(this.getObjectives());
    return objectives.some((objective) => {
      return objective.actionItem?.fastTrackEligible;
    });
  }
}

module.exports = GuidedLearningCategory;
