import { type QAClassName } from '@common/interfaces/SharedTypes';
import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import {
  TablePagination as MuiTablePagination,
  styled,
  type TablePaginationTypeMap as MuiTablePaginationTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTablePaginationCustomProps = {
  component?: React.ElementType; // Reset to optional instead of MuiOverridableComponent default of required
  qaClassName: QAClassName;
};

export type AxTablePaginationTypeMap<P = object, D extends React.ElementType = 'div'> = MuiTablePaginationTypeMap<P & AxTablePaginationCustomProps, D>;

export type AxTablePaginationProps<
  D extends ElementType = AxTablePaginationTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTablePaginationTypeMap<P & AxTablePaginationCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTablePagination',
  shouldForwardProp: (prop: string) => {
    return prop !== 'qaClassName';
  }
};

const StyledAxTablePagination = styled(MuiTablePagination, styledOptions)<AxTablePaginationProps>(() => {
  return {
    '.MuiTablePagination-selectLabel': {
      margin: 0
    },
    '.MuiTablePagination-displayedRows': {
      margin: 0
    }
  };
});

/**
 * A component that renders pagination controls for the table.
 *
 * ## Links
 * - • [MUI | Table API](https://mui.com/material-ui/api/table-pagination/)
 * - • [Figma | Mockup](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=12172-4719&m=dev)
 */
export const AxTablePagination: MuiOverridableComponent<AxTablePaginationTypeMap> = forwardRef(({
  qaClassName,
  SelectProps,
  backIconButtonProps,
  nextIconButtonProps,
  ...otherProps
}: AxTablePaginationProps, ref: Ref<unknown>) => {
  const tablePaginationProps: AxTablePaginationProps = {
    qaClassName,
    SelectProps: {
      ...SelectProps,
      className: joinClassNames(SelectProps?.className, `${ qaClassName }-select`)
    },
    backIconButtonProps: {
      ...backIconButtonProps,
      disableRipple: true,
      disableTouchRipple: true,
      className: joinClassNames(backIconButtonProps?.className, `${ qaClassName }-prev`)
    },
    nextIconButtonProps: {
      ...nextIconButtonProps,
      disableRipple: true,
      disableTouchRipple: true,
      className: joinClassNames(nextIconButtonProps?.className, `${ qaClassName }-next`)
    },
    ...otherProps
  };

  //  🚨 Warning
  // `backIconButtonProps` & `nextIconButtonProps` are deprecated in V6.
  // However, slotsProps doesn't show up in our current MUI version.
  // This also forced us to remove extra margin in the pagination components with CSS classes.
  // See more: https://mui.com/material-ui/api/table-pagination/#table-pagination-prop-backIconButtonProps

  return (
    <StyledAxTablePagination
      ref={ ref }
      { ...tablePaginationProps }
    />
  );
});

export default AxTablePagination;
