const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const { ItemView } = require('Marionette');
const TopicLevelActionType = require('@common/data/enums/TopicLevelActionType');

const {
  DAILY_REINFORCEMENT,
  TOPIC_CERTIFICATION,
  TOPIC_EXTRA_TRAINING,
  TOPIC_REFRESHER,
  TOPIC_INTRO,
  PATH_ENROLLED,
  PATH_ASSIGNED,
  SUMMARY_EXAMS,
  SUMMARY_CERTIFICATIONS,
  SUMMARY_REFRESHER,
  SUMMARY_INTRO,
  TRAINING_PAGE
} = require('@common/components/trainingCards/enums/TrainingCardTypes');

const {
  RESUME: META_DATA_TYPE_RESUME,
  START: META_DATA_TYPE_START,
  CONTINUE: META_DATA_TYPE_CONTINUE,
  BOOST: META_DATA_TYPE_BOOST,
  BOOST_NEEDS_COACHING: META_DATA_TYPE_BOOST_NEEDS_COACHING,
  BOOST_UNDERCONFIDENT: META_DATA_TYPE_BOOST_UNDERCONFIDENT,
  BOOST_OVERCONFIDENT: META_DATA_TYPE_BOOST_OVERCONFIDENT,
  BOOST_RECOMMENDATION: META_DATA_TYPE_BOOST_RECOMMENDATION,
  CERTIFIED_LEVEL: META_DATA_TYPE_CERTIFIED_LEVEL
} = require('@common/components/trainingCards/enums/TrainingCardMetaDataTypes');

const {
  CURRENT_CONFIDENCE
} = require('@common/components/trainingCards/enums/TrainingCardImpactMetricTypes');

const {
  BEGINNER: PROGRESS_LEVEL_BEGINNER,
  INTERMEDIATE: PROGRESS_LEVEL_INTERMEDIATE,
  NOT_STARTED: PROGRESS_LEVEL_NOT_STARTED,
  GRADUATE: PROGRESS_LEVEL_GRADUATE,
  EXPERT: PROGRESS_LEVEL_EXPERT
} = require('@common/components/trainingCards/enums/TrainingCardProgressLevelTypes');


const PROGRESS_LEVEL_STRING_KEYS = {
  [PROGRESS_LEVEL_BEGINNER]: 'training.cards.info.progressLevelBeginner',
  [PROGRESS_LEVEL_INTERMEDIATE]: 'training.cards.info.progressLevelIntermediate',
  [PROGRESS_LEVEL_GRADUATE]: 'training.cards.info.progressLevelGraduate',
  [PROGRESS_LEVEL_NOT_STARTED]: 'training.cards.info.progressLevelNotStarted',
  [PROGRESS_LEVEL_EXPERT]: 'training.cards.info.progressLevelExpert'
};

class TrainingCardBottomInfoView extends ItemView {
  getTemplate() {
    return `
      <div class="training-card__bottom-info-text-wrapper">
        <% if (iconClass) { %>
          <span class="item-icon <%- iconClass %> training-card__bottom-info-text-icon"></span>
        <% } %>
        <p class="training-card__bottom-info-text <%- textWarningClass %>"><%= infoText %></p>
      </div>
      <div class="training-card__bottom-info-action">
        <%= axButton({ className: fullCardButtonCssClass, ariaLabel: fullCardButtonLabel, label: t('training.cards.actions.view') }) %>
        <% if (displayActionButton) { %>
          <%= axButton({ className: 'js-action-button' + actionButtonCssClass + ' ' + actionPrimaryCssClass, label: actionButtonText }) %>
        <% } %>
        <% if (isLocked) { %>
          <span class="icon-lock"></span>
        <% } %>
      </div>
    `;
  }

  ui() {
    return {
      actionButton: '.js-action-button'
    };
  }

  events() {
    return {
      'click @ui.actionButton': '_handleActionClick'
    };
  }

  className() {
    return 'training-card__bottom-info';
  }

  templateHelpers() {
    const title = this.getOption('title');
    const fullCardCallback = this.getOption('fullCardCallback');
    const hasFullCardCallback = _.isFunction(fullCardCallback);
    const fullCardButtonCssClass = hasFullCardCallback ? 'off-screen' : 'hidden';
    const fullCardButtonLabel = I18n.t('training.cards.viewWithCardTitle', { title });
    const actionMetaDataType = this.getOption('actionType');
    const actionCallback = this.getOption('actionCallback');
    const isRecommended = this.getOption('isRecommended');
    const isLocked = actionMetaDataType === TopicLevelActionType.LOCKED;
    const isDailyReinforcement = this.getOption('trainingCardType') === DAILY_REINFORCEMENT;
    const fastTrackEligible = this.getOption('fastTrackEligible') || false;
    const actionType = actionMetaDataType === TopicLevelActionType.RESUME && fastTrackEligible
      ? TopicLevelActionType.RESUME_FAST_TRACK
      : actionMetaDataType;

    const actionPrimaryCssClass = this._getButtonPrimaryCssClass(actionMetaDataType, isRecommended, isDailyReinforcement);
    const actionButtonText = this.getButtonText(actionType);
    const actionButtonCssClass = _.isFunction(actionCallback) && actionButtonText ? ' qa-training-card-button' : 'hidden';

    // Conditions for when will show the action button.
    const displayActionButton
    = ((
      this.getOption('trainingCardType') !== TOPIC_EXTRA_TRAINING
      || window.app.sessionController.isExtraTrainingAvailable()
      || actionMetaDataType === META_DATA_TYPE_RESUME
    )
      && this.getOption('isStartable')
      && !isLocked
      && app.sessionController.isMinDailyTrainingFulfilled()
    )
    || actionMetaDataType === TopicLevelActionType.VIEW_ALL;

    const infoData = this._getInfoData();

    return {
      infoText: infoData.text || '',
      iconClass: infoData.iconClass || '',
      textWarningClass: infoData.showWarning ? 'training-card__bottom-info-text--warning' : '',
      fullCardButtonCssClass,
      fullCardButtonLabel,
      actionButtonCssClass,
      actionPrimaryCssClass,
      actionButtonText,
      displayActionButton,
      isLocked
    };
  }

  _getButtonPrimaryCssClass(actionType, isRecommended, isDailyReinforcement) {
    const isActionPrimary = (actionType === META_DATA_TYPE_RESUME || isRecommended) || isDailyReinforcement;

    if (isActionPrimary) {
      return 'ax-button--branded';
    } else if (actionType === TopicLevelActionType.VIEW_ALL) {
      return 'ax-button--ghost';
    }

    return '';
  }

  _getInfoData() {
    const trainingCardType = this.getOption('trainingCardType');
    const numAvailable = this.getOption('numAvailable');
    const actionMetaData = this.getOption('actionMetaData');
    const isGraduatedFromAllLevel = this.getOption('isGraduatedFromAllLevel');
    const isOverdue = this.getOption('isOverdue');
    const isDueToday = this.getOption('isDueToday');
    const isComplete = this.getOption('isComplete');
    const numCompleted = this.getOption('numCompleted');
    const total = this.getOption('total');
    const daysUntilDue = this.getOption('daysUntilDue');

    const result = {
      text: ''
    };
    let tempData;

    switch (trainingCardType) {
      case SUMMARY_EXAMS:
      case SUMMARY_CERTIFICATIONS:
      case SUMMARY_REFRESHER:
      case SUMMARY_INTRO:
        result.text = numAvailable ? I18n.t('training.cards.info.numAvailable', { numAvailable }) : '';
        break;

      case TOPIC_CERTIFICATION:
      case TOPIC_EXTRA_TRAINING:
      case TOPIC_REFRESHER:
      case TOPIC_INTRO:
        tempData = this._getInfoDataForTopic({
          actionMetaData,
          isGraduatedFromAllLevel
        });
        result.text = tempData.text;
        result.iconClass = tempData.iconClass;
        break;

      case PATH_ASSIGNED:
      case PATH_ENROLLED:
        tempData = this._getInfoDataForPath({
          isOverdue,
          isDueToday,
          isComplete,
          numCompleted,
          total,
          daysUntilDue
        });
        result.text = tempData.text;
        result.iconClass = tempData.iconClass;
        result.showWarning = tempData.showWarning;
        break;

      case TRAINING_PAGE:
        result.text = I18n.t('start.homepage.continueTrainingProgressExtraTraining', {
          part: numCompleted,
          total
        });
        break;

      case DAILY_REINFORCEMENT:
        tempData = this._getInfoDataForReinforcement({
          actionMetaData
        });
        result.text = tempData.text;
        break;

      default:
        result.text = '';
    }

    return result;
  }

  _getInfoDataForReinforcement({
    actionMetaData
  }) {
    const result = {
      text: ''
    };

    const textArray = [];

    if (actionMetaData.eligiblePoints) {
      textArray.push(
        actionMetaData.eligiblePoints > 1
          ? I18n.t('assessments.item.rewardPoints.possible', { points: actionMetaData.eligiblePoints })
          : I18n.t('assessments.item.rewardPoints.singular')
      );
    }

    if (actionMetaData.questionCount) {
      textArray.push(
        actionMetaData.questionCount > 1
          ? I18n.t('assessments.item.content.questions.plural', { count: actionMetaData.questionCount })
          : I18n.t('assessments.item.content.questions.singular')
      );
    }


    if (actionMetaData) {
      result.text = textArray.join('&nbsp; ');
    }

    return result;
  }

  _getInfoDataForPath({
    isOverdue,
    isDueToday,
    isComplete,
    numCompleted,
    total,
    daysUntilDue
  }) {
    const result = {
      text: ''
    };

    if (isOverdue) {
      result.text = I18n.t('training.cards.info.overdue');
      result.iconClass = 'icon-overdue training-card__bottom-info-text-icon--overdue';
      result.showWarning = true;
    } else if (isComplete) {
      result.text = I18n.t('training.cards.info.complete');
      result.iconClass = 'icon-ok_circle training-card__bottom-info-text-icon--ok_circle';
    } else if (isDueToday) {
      result.text = I18n.t('assessments.item.dueBy.today');
      result.iconClass = 'icon-milestone training-card__bottom-info-text-icon--milestone';
      result.showWarning = true;
    } else if (daysUntilDue === 1) {
      result.text = I18n.t('assessments.item.dueBy.singular');
      result.iconClass = 'icon-milestone training-card__bottom-info-text-icon--milestone';
      result.showWarning = true;
    } else if (Number.isInteger(daysUntilDue) && daysUntilDue < 5) {
      result.text = I18n.t('assessments.item.dueBy.plural', { daysLeft: daysUntilDue });
      result.iconClass = 'icon-milestone training-card__bottom-info-text-icon--milestone';
      result.showWarning = true;
    } else {
      result.text = I18n.t('training.cards.info.completedOfTotal', {
        completed: numCompleted,
        total
      });
      result.iconClass = 'icon-list training-card__bottom-info-text-icon--list';
    }

    return result;
  }

  _getInfoDataForTopic({
    actionMetaData,
    isGraduatedFromAllLevel
  }) {
    const result = {
      text: ''
    };
    const actionMetaDataType = actionMetaData ? actionMetaData.type : null;

    switch (actionMetaDataType) {
      case META_DATA_TYPE_RESUME:
        result.text = I18n.t('training.cards.info.completedOfTotal', {
          total: actionMetaData.total,
          completed: actionMetaData.completed
        });
        result.iconClass = 'icon-list';
        break;

      case META_DATA_TYPE_CONTINUE:
        if ([
          PROGRESS_LEVEL_BEGINNER,
          PROGRESS_LEVEL_INTERMEDIATE,
          PROGRESS_LEVEL_GRADUATE,
          PROGRESS_LEVEL_NOT_STARTED,
          PROGRESS_LEVEL_EXPERT
        ].includes(actionMetaData.progressLevel)) {
          result.text = I18n.t(PROGRESS_LEVEL_STRING_KEYS[actionMetaData.progressLevel], { level: actionMetaData.level });
        }

        if (actionMetaData.progressLevel === PROGRESS_LEVEL_BEGINNER) {
          result.iconClass = 'icon-beginner';
        } else if (actionMetaData.progressLevel === PROGRESS_LEVEL_INTERMEDIATE) {
          result.iconClass = 'icon-intermediate';
        } else if (actionMetaData.progressLevel === PROGRESS_LEVEL_NOT_STARTED) {
          result.iconClass = 'icon-not-started';
        } else if (actionMetaData.progressLevel === PROGRESS_LEVEL_GRADUATE) {
          result.iconClass = 'icon-graduate';
        }

        break;

      case META_DATA_TYPE_START:
        if (actionMetaData.level) {
          result.text = I18n.t(PROGRESS_LEVEL_STRING_KEYS[actionMetaData.progressLevel], { level: actionMetaData.level });
        } else {
          result.text = I18n.t('training.cards.info.notStarted');
        }
        result.iconClass = 'icon-not-started';
        break;

      case META_DATA_TYPE_BOOST:
        if ((actionMetaData.score || actionMetaData.score === 0) && actionMetaData.level) {
          result.text = I18n.t('training.cards.info.averageScoreWithLevel', {
            score: actionMetaData.score,
            level: actionMetaData.level
          });
        } else if (actionMetaData.score) {
          result.text = I18n.t('training.cards.info.averageScore', { score: actionMetaData.score });
        }

        if ((actionMetaData.score || actionMetaData.score === 0)) {
          if (actionMetaData.score >= 80) {
            result.iconClass = 'icon-ok_circle training-card__bottom-info-text-icon--ok';
            if (isGraduatedFromAllLevel) {
              result.iconClass = 'icon-graduate training-card__bottom-info-text-icon--graduate';
            }
          } else {
            result.iconClass = 'icon-exclamation training-card__bottom-info-text-icon--exclamation';
          }
        }
        break;

      case META_DATA_TYPE_BOOST_UNDERCONFIDENT:
        result.text = I18n.t('training.cards.info.boostConfidence');
        result.iconClass = 'icon-bulb training-card__bottom-info-text-icon--boost-confidence';
        break;

      case META_DATA_TYPE_BOOST_RECOMMENDATION:
        if (actionMetaData.mostImpactingMetric === CURRENT_CONFIDENCE) {
          result.text = I18n.t('training.cards.info.boostConfidence');
          result.iconClass = 'icon-knowledge';
        } else {
          result.text = I18n.t('training.cards.info.boostKnowledge');
          result.iconClass = 'icon-knowledge';
        }
        break;

      case META_DATA_TYPE_BOOST_OVERCONFIDENT:
        result.text = I18n.t('training.cards.info.boostOverconfident');
        result.iconClass = 'icon-overconfident';
        break;

      case META_DATA_TYPE_BOOST_NEEDS_COACHING:
        result.text = I18n.t('training.cards.info.needsCoaching');
        result.iconClass = 'icon-needs-coaching';
        break;

      case META_DATA_TYPE_CERTIFIED_LEVEL:
        result.text = I18n.t('training.cards.info.certified', { level: actionMetaData.level });
        result.iconClass = 'icon-certified';
        break;

      default:
        result.text = '';
    }

    return result;
  }

  getButtonText(actionType) {
    if (actionType) {
      switch (actionType) {
        case TopicLevelActionType.START_CERT:
          return I18n.t('start.startButton.certification');
        case TopicLevelActionType.VISIT_PATH:
          return I18n.t('selfDirected.viewPathBtn');
        case TopicLevelActionType.START:
          return I18n.t('start.startButton.start');
        case TopicLevelActionType.RESTART:
          return I18n.t('general.continue');
        case TopicLevelActionType.RETAKE:
          return I18n.t('assessments.item.action.fail');
        case TopicLevelActionType.RESUME:
          return I18n.t('assessments.item.action.resume');
        case TopicLevelActionType.RESUME_FAST_TRACK:
          return I18n.t('start.startButton.resumeFastTrack');
        case TopicLevelActionType.QUICK_QUIZ:
          return I18n.t('general.boost');
        case TopicLevelActionType.VIEW_ALL:
          return I18n.t('general.viewAll');
        default:
          return I18n.t('start.startButton.start');
      }
    }
    return '';
  }

  _handleActionClick(e) {
    const actionCallback = this.getOption('actionCallback');

    if (_.isFunction(actionCallback)) {
      actionCallback();
    }

    e.stopPropagation();
  }
}

module.exports = TrainingCardBottomInfoView;
