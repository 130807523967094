const { LayoutView } = require('Marionette');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const logging = require('logging');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const DetailViewConfig = require('@common/components/details/DetailViewConfig');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TitleHeaderTypeEnum = require('@common/components/titleHeader/TitleHeaderTypeEnum');
const CertificationStatus = require('@training/apps/training/enums/CertificationStatus');
const TopicCTAButtonHelper = require('@common/components/search/TopicCTAButtonHelper');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');
const TopicLevelActionType = require('@common/data/enums/TopicLevelActionType');

const { SEARCH } = require('@common/data/enums/SearchPageEnum');
const { LOCKED } = require('@common/data/enums/TopicLevelActionType');

const { RESUME } = require('@common/components/trainingCards/enums/TrainingCardMetaDataTypes');

const {
  getDueDateViewDefinition,
  getLevelsViewDefinition,
  getModulesViewDefinition,
  getQuestionsViewDefinition
} = require('@common/components/cardMetadata/MetadataStringHelper');

const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');

const _getStartableTopicLevel = (resultData) => {
  const action = resultData.get('action');
  const topicLevels = resultData.get('topicLevels') || [];

  if (action != null) {
    const result = topicLevels.filter((topicLevel) => {
      return action.topicLevel.level === topicLevel.level;
    });

    return result[0];
  }
  return topicLevels[0];

};

const _isCertApplicable = (startableTopicLevel) => {
  return startableTopicLevel != null && startableTopicLevel.isCertApplicable && startableTopicLevel.certDetails != null;
};

const _getBtnVisibilityClass = (resultData = {}) => {
  const session = window.app.sessionController.session;
  const action = resultData.get('action');
  const actionType = action && action.metadata && action.metadata.type;

  let isStartable = TopicCTAButtonHelper.isStartable(action, session);

  if (!isStartable) {
    const startableAction = TopicCTAButtonHelper.findStartableTopicLevelAction(resultData, session);

    if (startableAction != null) {
      isStartable = true;
      resultData.set('action', startableAction);
    }
  }

  // This logic was copied from `TrainingCardBottomInfoView.templateHelpers` - from the app you can see how the button
  // display is controlled in Training > Topics. The logic has been copied over to here to ensure the rules are identical.
  const displayActionButton
  = ((
    action.assessmentType !== 'ExtraTrainingQuestions'
    || window.app.sessionController.isExtraTrainingAvailable()
    || (actionType === RESUME)
  )
    && isStartable
    && actionType !== LOCKED
    && app.sessionController.isMinDailyTrainingFulfilled()
  );

  return displayActionButton ? '' : 'invisible';
};

const _getBottomTileViewDefinition = (resultData, itemViewOptions = {}) => {
  const startableTopicLevel = _getStartableTopicLevel(resultData);
  const btnVisibilityClass = _getBtnVisibilityClass(resultData);
  const action = resultData.get('action');
  const isLocked = action.actionType === LOCKED;

  let dueDate = null;
  if (_isCertApplicable(startableTopicLevel)) {
    dueDate = startableTopicLevel.certDetails.dueDate;
  }

  const viewDefinition = {
    bottomWrapperControllerDefinition: {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: LayoutView,
        className: 'search-result__lower full-width',
        template: `<div class="metaRegion tile__meta"></div>`,
        regions: {
          metaRegion: '.metaRegion'
        }
      },
      regionControllers: {
        metaRegion: {
          viewDefinition: {
            ViewClass: StackedCollectionView,
            viewConfigModel: DetailViewConfig,
            className: 'stacked-collection-view',
            viewConfigs: [
              getDueDateViewDefinition(dueDate),
              getLevelsViewDefinition(resultData.get('topicLevels').length),
              getModulesViewDefinition(startableTopicLevel),
              getQuestionsViewDefinition(startableTopicLevel)
            ]
          }
        }
      }
    }
  };

  if (isLocked) {
    viewDefinition.bottomWrapperControllerDefinition.viewDefinition.template = `
      <div class="metaRegion tile__meta"></div>
      <span class="icon-lock"></span>
    `;
  } else {
    viewDefinition.bottomWrapperControllerDefinition.viewDefinition.template = `
      <div class="metaRegion tile__meta"></div>
      <button class="axon-button btn qa-button-meta-action <%- btnVisibility %>" aria-label="<%- ariaLabel %>" type="button">
        <span aria-hidden="true" role="presentation" class="action-icon icon-play"></span>
        <span class="action-text"><%= btnText %></span>
      </button>
    `;

    if (action?.actionType === TopicLevelActionType.RESUME && action?.fastTrackEligible) {
      action.actionType = TopicLevelActionType.RESUME_FAST_TRACK;
    }

    viewDefinition.bottomWrapperControllerDefinition.viewDefinition.templateHelpers = {
      ariaLabel: resultData.get('title') + '. ' + I18n.t('start.startButton.topic'),
      btnVisibility: btnVisibilityClass,
      btnText: TopicCTAButtonHelper.getButtonText(action)
    };

    viewDefinition.bottomWrapperControllerDefinition.viewDefinition.events = {
      'click .axon-button': (evt) => {
        // We don't want the fullCard click event to trigger as well, so cancel event bubbling.
        evt.stopPropagation();
        TopicCTAButtonHelper.handleActionForType(
          action,
          AssessmentLaunchContext.TOPIC_SEARCH,
          itemViewOptions.startTrainingFn,
          {
            pageId: SEARCH,
            pageOptions: {
              searchCategory: itemViewOptions.searchPageState.get('searchCategory'),
              searchString: itemViewOptions.searchPageState.get('searchString'),
              pageNum: itemViewOptions.searchPageState.get('pageNum') + 1
            }
          }
        );
      },
      // NOTE: The the keypress event for the ENTER key on a button with focus ALSO triggers/bubbles as a click event,
      // so we need to capture that too and leave the actual event handling to the click event
      'keypress .axon-button': (evt) => {
        const code = evt.keyCode ? evt.keyCode : evt.which;
        if (code === '13') {
          evt.stopPropagation();
        }
      }
    };
  }

  return viewDefinition;
};

const _getTitleOptions = (resultData, itemViewOptions) => {
  return {
    titleOptions: {
      title: _getTitle(resultData),
      pretext: resultData.get('subjectName'),
      titleType: TitleHeaderTypeEnum.PRETEXT_TITLE,
      titleTag: itemViewOptions.titleTag
    }
  };
};

const _getTitle = (resultData) => {
  let topicTitle = resultData.get('title');
  const certStatusToIconMap = {
    [CertificationStatus.DUE]: '<span class="icon-certification due" aria-hidden="true" role="presentation"></span>',
    [CertificationStatus.OVERDUE]: '<span class="icon-certification overdue" aria-hidden="true" role="presentation"></span>',
    [CertificationStatus.CERTIFIED]: '<span class="icon-certification certified" aria-hidden="true" role="presentation"></span>'
  };

  const startableTopicLevel = _getStartableTopicLevel(resultData);

  if (_isCertApplicable(startableTopicLevel)) {
    const iconText = certStatusToIconMap[startableTopicLevel.certDetails.status];
    if (iconText) {
      topicTitle += iconText;
    }
  }
  return topicTitle;

};

const _getIsAssigned = (resultData) => {
  const startableTopicLevel = _getStartableTopicLevel(resultData);
  return startableTopicLevel.isCertApplicable || resultData.get('assigned');
};

const _getTemplateHelpers = (resultData) => {
  return {
    linkAttributes: `aria-label="${ I18n.t('selfDirected.search.viewTopicDetailsAria', {title: resultData.get('title')}) }"`,
    topRegionAttributes: '',
    topRegionClass: '',
    linkText: I18n.t('selfDirected.search.viewDetails'),
    isAssigned: _getIsAssigned(resultData)
  };
};

const topicSearchResultControllerDefinition = (resultData, itemViewOptions = {}) => {
  /* Log an error if:
   * (a) we don't have resultData provided; or
   * (b) the topic results provided do not have topic levels
   */
  if (!resultData) {
    logging.warn('No topic data returned by the search');
    window.app.layout.flash.error(I18n.t('selfDirected.search.errors.noSearchData'));
    return undefined;
  } else if (!_.isFunction(resultData.get) || _.isEmpty(resultData.get('topicLevels'))) {
    logging.warn('No topic levels associated with the returned topic');
    logging.warn(JSON.stringify(resultData));
    window.app.layout.flash.error(I18n.t('selfDirected.search.errors.noTopicLevels'));
    return undefined;
  }

  const queryString = itemViewOptions.searchPageState ? itemViewOptions.searchPageState.get('searchString') : '';

  const defaultTileOptions = {
    templateOverride: require('@training/apps/search/TopicSearchResultTileView.html'),
    templateHelpersOverride: _getTemplateHelpers(resultData),
    attributesOverride: {
      'aria-label': resultData.get('title')
    },
    tabbableLinkClickableCard: true,
    cardClass: 'search-result__tile',
    hasLink: true,
    linkOptions: {
      target: 'fullCard',
      callback: () => {
        Backbone.history.navigate(`#hub/search/topic/${ encodeURIComponent(resultData.get('id')) }?query=${ encodeURIComponent(queryString) }`, {
          trigger: true
        });
      }
    }
  };

  Object.assign(defaultTileOptions, _getTitleOptions(resultData, itemViewOptions), _getBottomTileViewDefinition(resultData, itemViewOptions));

  //Extend the tile options with events and behaviours related to rendering an image, used to extend the base card functionality behind it's original use
  return Object.assign(getBaseCardControllerDefinition(defaultTileOptions), _getImageView(resultData));
};

const _getImageView = (resultData) => {
  const eventObject = {
    delegateEvents: {
      'view:attach': (layoutController) => {
        const view = layoutController.getView();
        const media = layoutController.options.media;
        const parent = view.$el;
        let topicImage = null;

        if (!_.isEmpty(media)) {
          topicImage = ImageViewerFactory.createViewerInstance({
            media,
            $el: parent.find('.base-card__image')
          });

          if (topicImage != null) {
            view.listenTo(topicImage, 'image:loaded', () => {
              view.trigger('image:loaded');
            });
            topicImage.render();
            view.onResize = () => {
              topicImage.resize();
            };
          }
        }
      }
    },
    media: resultData.get('media'),
    behaviors: {
      Resizable: {}
    }
  };

  return eventObject;
};

module.exports = {
  topicSearchResultControllerDefinition
};
