import { type AxonifyThemeType } from '@common/modules/react/themes/AxonifyTheme';
import { AxIcon } from '@common/modules/react/themes/components';
import {
  Breadcrumbs as MuiBreadcrumbs,
  styled,
  type BreadcrumbsTypeMap as MuiBreadcrumbsTypeMap,
  type SxProps
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxBreadcrumbSxProps = SxProps<AxonifyThemeType> & {
  color?: string;
  textDecoration?: string;
  fontSize?: string;
};

type AxBreadcrumbsCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  sx?: AxBreadcrumbSxProps;
  /**
   * Styles applied to the separator element
   */
  separatorSx?: AxBreadcrumbSxProps;
  /**
   * If `true`, the component won't use any default style and the control goes fully to the consumer
   * @default false
   */
  useControlledStyles?: boolean
};

export type AxBreadcrumbsTypeMap<P = object, D extends React.ElementType = 'nav'> = MuiBreadcrumbsTypeMap<P & AxBreadcrumbsCustomProps, D>;

export type AxBreadcrumbsProps<
  D extends ElementType = AxBreadcrumbsTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxBreadcrumbsTypeMap<P & AxBreadcrumbsCustomProps, D>, D>;

const styleOptions = {
  name: 'AxBreadcrumbs',
  shouldForwardProp: (prop: string) => {
    return prop !== 'useControlledStyles' && prop !== 'separatorSx';
  }
};

const StyledAxBreadcrumbs = styled(MuiBreadcrumbs, styleOptions)<AxBreadcrumbsProps>(({
  theme,
  sx,
  useControlledStyles,
  separatorSx
}) => {
  if (useControlledStyles) {
    return {};
  }

  return {
    '.MuiBreadcrumbs-separator': {
      marginRight: theme.uiKit.spacingXS,
      marginLeft: theme.uiKit.spacingXS
    },
    '.MuiBreadcrumbs-separator > *': {
      fontSize: '1rem',
      color: theme.uiKit.colorGrey80,
      fontWeight: theme.uiKit.fontWeightBold,
      opacity: 0.5,
      ...separatorSx
    },
    '.MuiBreadcrumbs-li > *': {
      fontSize: theme.uiKit.fontSizeXS,
      color: theme.uiKit.colorGrey70,
      ...sx
    }
  };
});

function RightChevronIcon() {
  return <AxIcon className='icon-chevron_right' />;
}

/**
 * Breadcrumbs allow users to keep track of their locations within programs, documents, or websites.
 *
 * The component works with a composition of `AxLink` and `AxTypography`. If you want to have a different style for the links, check _Customization_ story.
 *
 * As recomendation, don't add the page title to the breadcrumb. The breadcrumbs should only show the hierarchy of the page.
 *
 * ### Links
 * - [MUI](https://mui.com/components/breadcrumbs/)
 * - [UIKit Figma](https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?node-id=7244:5633&)
 *
 * @example
 * <AxBreadcrumbs>
 *   <AxLink href='/' qaClassName='qa-link-root'>Root</AxLink>
 *   <AxLink href='/material-ui/getting-started/installation/' qaClassName='qa-link-level1'>Level 1</AxLink>
 *   <AxLink href='/material-ui/getting-started/installation/' qaClassName='qa-link-level2'>Level 2</AxLink>
 * </AxBreadcrumbs>
 *
 */
export const AxBreadcrumbs: MuiOverridableComponent<AxBreadcrumbsTypeMap> = forwardRef(({
  children,
  separator = <RightChevronIcon />,
  ...otherProps
}: AxBreadcrumbsProps, ref: Ref<HTMLElement>) => {
  const BreadcrumbsProps: AxBreadcrumbsProps = {
    children,
    separator,
    ...otherProps
  };

  return (
    <StyledAxBreadcrumbs { ...BreadcrumbsProps } ref={ ref }>
      { children }
    </StyledAxBreadcrumbs>
  );
});

export default AxBreadcrumbs;
