import { type PropsWithChildren } from 'react';

import { type QAClassName } from '@common/interfaces/SharedTypes';
import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import {
  DialogActions,
  type DialogActionsProps
} from '@mui/material';

type AxDialogDefaultActionBarProps = PropsWithChildren<DialogActionsProps & {
  qaClassName: QAClassName
}>;

export default function AxDialogDefaultActionBar(props: AxDialogDefaultActionBarProps): JSX.Element {
  const {
    children,
    qaClassName,
    className,
    ...dialogActionProps
  } = props;

  return children != null ? <DialogActions { ...dialogActionProps } className={ joinClassNames(qaClassName, className) }>{ children }</DialogActions> : <></>;
}
