const logging = require('logging');
const { guardMethod } = require('DecaffeinateHelpers');
const { history } = require('Backbone');

const I18n = require('@common/libs/I18n');
const $ = require('jquery');

const PageHeaderDefinitionFactory = require('@training/widgets/pageHeader/PageHeaderDefinitionFactory');

const ActivitiesController = require('@training/apps/training/ActivitiesController');
const GameManagerFactory = require('@training/apps/training/controllers/GameManagerFactory');
const QuizManager = require('@training/apps/training/controllers/QuizManager');
const GamesData = require('@training/apps/training/models/GamesData');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const SelectGamePage = require('@training/apps/training/views/SelectGamePage');
const GameInfoPage = require('@training/apps/training/views/GameInfoPage');
const GameChallengeSelectPage = require('@training/apps/training/views/GameChallengeSelectPage');

const ModalStatus = require('@common/data/models/ModalStatus');
const SelectGameHowToModal = require('@training/apps/training/views/SelectGameHowToModal');

const gameManager = null;
const quizManager = null;

class QuestionsController extends ActivitiesController {

  constructor(parentProcessor, sessionModel) {
    super(parentProcessor, sessionModel);

    this.getNextActivity = this.getNextActivity.bind(this);
    this.processActivities = this.processActivities.bind(this);
    this.selectGame = this.selectGame.bind(this);
    this.createGameChallenge = this.createGameChallenge.bind(this);
    this.acceptGameChallenge = this.acceptGameChallenge.bind(this);
    this.startGame = this.startGame.bind(this);
    this.delegateActivities = this.delegateActivities.bind(this);
    this.showGameSelection = this.showGameSelection.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);

    this.tenantStore = TenantPropertyProvider.get();
    this.gamesData = new GamesData({}, {
      hideTeamGames: window.apps.auth.session.hasCustomTeamLeaderboardButNoTeam()
    });

    this.selectGameModalStatus = new ModalStatus({
      modalName: 'gameSelection'
    });

    this._promiseAllReady = this.sessionModel.currentAssessment.activities.markAllDeletedQuestionActivitesAsSkipped();
  }

  getGameManager() {
    return gameManager != null ? gameManager : (new GameManagerFactory()).createGameManager(this, this.sessionModel);
  }

  getQuizManager() {
    return quizManager != null ? quizManager : new QuizManager(this, this.sessionModel);
  }

  getNextActivity() {
    return this.sessionModel.currentAssessment.activities.getNextQuestion();
  }

  processActivities() {
    logging.debug('Processing questions activities');

    if (this.canPickGame()) {
      if (this.gamesDataDeferred == null) {
        this.gamesDataDeferred = $.when(this.gamesData.getGames(), this.selectGameModalStatus.fetch());
      }

      this.gamesDataDeferred.done(this.showGameSelection);
      return this.gamesDataDeferred.fail(this.delegateActivities);
    }

    return this.delegateActivities();
  }

  canPickGame() {
    const isExtraTraining = this.sessionModel.currentAssessment.isExtraTraining();
    const shouldPickGame = this.sessionModel.currentAssessment.shouldPickGame();
    const isFastTrack = this.sessionModel.currentAssessment.isFastTrack();
    const gamesAllowedInExtraTraining = this.tenantStore.getProperty('gamesAllowedInExtraTraining');

    return shouldPickGame && !isFastTrack && (!isExtraTraining || gamesAllowedInExtraTraining);
  }

  selectGame(gameId) {
    return this.sessionModel.currentAssessment.selectGame(gameId, {
      success: () => {
        logging.info(`Successfully selected game id ${ gameId }`);
        return this.startGame();
      },
      error() {
        logging.error(`An error occured while selecting game id ${ gameId }`);
      }
    });
  }

  createGameChallenge(gameId, userId, includeWager = false) {
    return this.sessionModel.currentAssessment.selectGame(gameId, {
      success: () => {
        logging.info(`Successfully selected game id ${ gameId }, creating a challenge with user id ${ userId }`);

        const action = {
          mechanic: {
            mechanicId: 'challenge'
          },
          actionType: 'createChallenge',
          actionBody: {
            // camelcase identifier is sent to and required by the server
            /* eslint-disable camelcase */
            challengee_id: userId,
            includeWager
          }
        };

        return this.sessionModel.currentAssessment.performGameActions([action])
          .done( () => {
            logging.info(`Successfully creating a challenge with user id ${ userId } for game id ${ gameId }`);
            return this.startGame();
          })
          .fail( () => {
            return logging.error(`An error occured while challenging user id ${ userId } to play game id ${ gameId }`);
          });
      },
      error() {
        logging.error(`An error occured while selecting game id ${ gameId }, no chellenge will be created`);
      }
    });
  }

  acceptGameChallenge(gameId, challengeId) {
    return this.sessionModel.currentAssessment.selectGame(gameId, {
      success: () => {
        logging.info(`Successfully selected game id ${ gameId }, accepting game challenge id ${ challengeId }`);

        const action = {
          mechanic: {
            mechanicId: 'challenge'
          },
          actionType: 'acceptChallenge',
          actionBody: {
            challenge_id: challengeId
          }
        };

        return this.sessionModel.currentAssessment.performGameActions([action])
          .done( () => {
            logging.info(`Successfully accepted challenge id ${ challengeId }`);
            return this.startGame();
          })
          .fail( () => {
            return logging.error(`An error occured while accepting challenge id ${ challengeId }`);
          });
      },
      error() {
        logging.error(`An error occured while selecting game id ${ gameId }, chellenge will not be accepted`);
      }
    });
  }

  startGame() {
    return this.delegateActivities();
  }

  delegateActivities() {
    this.toggleQuestionMode();

    window.app.layout.togglePageHeader(true);
    this.parentProcessor.showAssessmentPageTitle();
    if (this.sessionModel.currentAssessment.gamePlay.isInProgress()) {
      return this.getGameManager().processActivities();
    } else if (this.getNextActivity()) {
      return this.getQuizManager().processActivities();
    }
    return this.finishedProcessing();

  }

  toggleQuestionMode(toggle = true) {
    window.app.layout.toggleFooter(!toggle);
  }

  showGameSelection() {
    logging.debug('Picking game...');

    const v = new SelectGamePage({
      model: this.gamesData,
      selectGame: this.selectGame,
      selectGameModalStatus: this.selectGameModalStatus
    });

    const headerDefinition = PageHeaderDefinitionFactory({
      pageClassName: 'select-game-page',
      iconClass: 'icon-games',
      iconLabel: I18n.t('selectGame.whichGameToPlay'),
      text: I18n.t('selectGame.whichGameToPlay'),
      showTooltip: true,
      ariaLabel: I18n.t('referral.howTo.link'),
      tooltipEvents: {
        'click .js-how-to-link': () => {
          if (v.isDestroyed) {
            return;
          }
          SelectGameHowToModal.displayModal(v.howToDialogRegion);
        }
      }
    });

    window.app.layout.togglePageHeader(true);
    window.app.layout.setPageTitleBarLeftDefinition(headerDefinition);
    window.app.layout.setView(v);
    history.navigate('#games');
  }

  showGameInfo(gameId, challengeId) {
    const selectGame = (selectedGameId, selectedChallengeId) => {
      if (selectedChallengeId != null) {
        this.acceptGameChallenge(selectedGameId, selectedChallengeId);
      } else {
        this.selectGame(selectedGameId);
      }
    };

    const gameInfoPage = new GameInfoPage({
      gameId,
      challengeId,
      selectGame
    });

    const headerDefinition = PageHeaderDefinitionFactory({
      iconClass: 'icon-games',
      text: I18n.t('selectGame.HowToPlay')
    });

    window.app.layout.togglePageHeader(true);
    window.app.layout.setPageTitleBarLeftDefinition(headerDefinition);
    window.app.layout.setView(gameInfoPage);
  }

  showGameChallengeSelection(gameId) {
    const challengeUser = (game, user, includeWager) => {
      return this.createGameChallenge(game.id, user.id, includeWager);
    };

    const gameChallengeSelectPage = new GameChallengeSelectPage({
      gameId,
      challengeUser
    });

    const headerDefinition = PageHeaderDefinitionFactory({
      iconClass: 'icon-games',
      text: I18n.t('selectGame.gameInfo.challengeFriend')
    });

    window.app.layout.togglePageHeader(true);
    window.app.layout.setPageTitleBarLeftDefinition(headerDefinition);
    window.app.layout.setView(gameChallengeSelectPage);
  }

  finishedProcessing() {
    logging.debug('Finished processing questions');

    // Make sure everything is ready before continuing...
    return this._promiseAllReady.done(() => {
      if (this.getNextActivity()) {
        return this.delegateActivities();
      }
      this.toggleQuestionMode(false);
      return guardMethod(this.parentProcessor, 'processActivities', (o) => {
        return o.processActivities();
      });

    }).fail(() => {
      logging.error('Daily training was uncompleteable for some reason. User is now stuck in training. Fix immediately!');
      throw new Error('An exception occured while trying to complete your Daily training.');
    });
  }
}

module.exports = QuestionsController;
