import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitTag(cssVars: CSSStyleDeclaration) {
  return {
    tagBorderColor: getCssValue('--ax-tag-border-color', cssVars),

    tagArticleBackgroundColor: getCssValue('--ax-tag-article-color-bg', cssVars),
    tagArticleTextColor: getCssValue('--ax-tag-article-color-text', cssVars),

    tagRecommendationBackgroundColor: getCssValue('--ax-tag-recommendation-color-bg', cssVars),
    tagRecommendationTextColor: getCssValue('--ax-tag-recommendation-color-text', cssVars),
    tagRecommendationBorderColor: getCssValue('--ax-tag-recommendation-border-color', cssVars),

    tagBetaBackgroundColor: getCssValue('--ax-tag-beta-color-bg', cssVars),
    tagBetaTextColor: getCssValue('--ax-tag-beta-color-text', cssVars),
    tagBetaBorderColor: getCssValue('--ax-tag-beta-border-color', cssVars),

    tagCertificationBackgroundColor: getCssValue('--ax-tag-certification-color-bg', cssVars),
    tagCertificationTextColor: getCssValue('--ax-tag-certification-color-text', cssVars),

    tagEvaluationBackgroundColor: getCssValue('--ax-tag-evaluation-color-bg', cssVars),
    tagEvaluationTextColor: getCssValue('--ax-tag-evaluation-color-text', cssVars),

    tagEventBackgroundColor: getCssValue('--ax-tag-event-color-bg', cssVars),
    tagEventTextColor: getCssValue('--ax-tag-event-color-text', cssVars),

    tagExamBackgroundColor: getCssValue('--ax-tag-exam-color-bg', cssVars),
    tagExamTextColor: getCssValue('--ax-tag-exam-color-text', cssVars),

    tagInReviewBackgroundColor: getCssValue('--ax-tag-in-review-color-bg', cssVars),
    tagInReviewTextColor: getCssValue('--ax-tag-in-review-color-text', cssVars),

    tagLinkBackgroundColor: getCssValue('--ax-tag-link-color-bg', cssVars),
    tagLinkTextColor: getCssValue('--ax-tag-link-color-text', cssVars),

    tagMilestoneBackgroundColor: getCssValue('--ax-tag-milestone-color-bg', cssVars),
    tagMilestoneTextColor: getCssValue('--ax-tag-milestone-color-text', cssVars),

    tagModuleBackgroundColor: getCssValue('--ax-tag-module-color-bg', cssVars),
    tagModuleTextColor: getCssValue('--ax-tag-module-color-text', cssVars),
    tagModuleBorderColor: getCssValue('--ax-tag-module-border-color', cssVars),

    tagPathBackgroundColor: getCssValue('--ax-tag-path-color-bg', cssVars),
    tagPathTextColor: getCssValue('--ax-tag-path-color-text', cssVars),

    tagPublishedBackgroundColor: getCssValue('--ax-tag-published-color-bg', cssVars),
    tagPublishedTextColor: getCssValue('--ax-tag-published-color-text', cssVars),

    tagQuestionBackgroundColor: getCssValue('--ax-tag-question-color-bg', cssVars),
    tagQuestionTextColor: getCssValue('--ax-tag-question-color-text', cssVars),

    tagReportedBackgroundColor: getCssValue('--ax-tag-reported-color-bg', cssVars),
    tagReportedTextColor: getCssValue('--ax-tag-reported-color-text', cssVars),

    tagTaskBackgroundColor: getCssValue('--ax-tag-task-color-bg', cssVars),
    tagTaskTextColor: getCssValue('--ax-tag-task-color-text', cssVars),

    tagTopicBackgroundColor: getCssValue('--ax-tag-topic-color-bg', cssVars),
    tagTopicTextColor: getCssValue('--ax-tag-topic-color-text', cssVars),

    tagValidationFailedBackgroundColor: getCssValue('--ax-tag-validation-failed-color-bg', cssVars),
    tagValidationFailedTextColor: getCssValue('--ax-tag-validation-failed-color-text', cssVars),
    tagValidationPassedBackgroundColor: getCssValue('--ax-tag-validation-passed-color-bg', cssVars),
    tagValidationPassedTextColor: getCssValue('--ax-tag-validation-passed-color-text', cssVars),
    tagValidationPendingBackgroundColor: getCssValue('--ax-tag-validation-pending-color-bg', cssVars),
    tagValidationPendingTextColor: getCssValue('--ax-tag-validation-pending-color-text', cssVars),

    tagSurveyBackgroundColor: getCssValue('--ax-tag-survey-color-bg', cssVars),
    tagSurveyTextColor: getCssValue('--ax-tag-survey-color-text', cssVars),
    tagSurveyBorderColor: getCssValue('--ax-tag-survey-border-color', cssVars),

    tagReassignedBackgroundColor: getCssValue('--ax-tag-reassigned-color-bg', cssVars),
    tagReassignedTextColor: getCssValue('--ax-tag-reassigned-color-text', cssVars),
    tagReassignedBorderColor: getCssValue('--ax-tag-reassigned-border-color', cssVars),

    tagExpiredBackgroundColor: getCssValue('--ax-tag-expired-color-bg', cssVars),
    tagExpiredTextColor: getCssValue('--ax-tag-expired-color-text', cssVars),

    tagDraftBackgroundColor: getCssValue('--ax-tag-draft-color-bg', cssVars),
    tagDraftTextColor: getCssValue('--ax-tag-draft-color-text', cssVars),
    tagDraftBorderColor: getCssValue('--ax-tag-draft-border-color', cssVars)
  };
}
