const $os = require('detectOS');
const _ = require('underscore');
const Marionette = require('Marionette');

const I18n = require('@common/libs/I18n');

const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');

const GuidedLearningObjectiveList = require('@common/components/guidedLearning/collections/GuidedLearningObjectiveList');
const GLItemViewHelpers = require('@common/components/guidedLearning/programs/GLItemViewHelpers');
const MilestoneItemView = require('@common/components/guidedLearning/programs/MilestoneItemView');
const ActionBarType = require('@common/components/actionBarButton/ActionBarType');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const { ReactControllerDefinitionFactory } = require('@common/modules/react');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const { default: AxonifyApps } = require('@common/data/enums/AxonifyApps');

require('jquery.velocity');

const SCROLL_DELAY = 50;
const HOVER_EFFECT_DELAY = 1000;

class ProgramOverviewPathContentView extends Marionette.LayoutView {
  className() {
    return 'learning-flow-overview program-overview overview-wrapper';
  }

  getTemplate() {
    return require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewPathContent.html');
  }

  regions() {
    return {
      eventsRegion: '.events-wrapper',
      milestonesRegion: '.milestones-wrapper',
      availableContainer: '.available-wrapper',
      unavailableContainer: '.unavailable-wrapper',
      fastTrackDialogRegion: '.js-fast-track-how-to__dialog-region',
      fastTrackBannerRegion: '.js-fast-track-banner-region'
    };
  }

  ui() {
    return {
      overviewHeader: '.overview-header',
      descriptionWrapper: '.description-wrapper',
      bannerWrapper: '.unavailable-banner-wrapper'
    };
  }

  initialize(options = {}) {
    super.initialize(options);
    ({
      objectiveStarter: this.objectiveStarter,
      objectiveValidator: this.objectiveValidator,
      sortedObjectives: this.availSortedObjectives,
      viewTopicDrillDown: this.viewTopicDrillDown,
      isSelfDirected: this.isSelfDirected,
      isEnrolled: this.isEnrolled,
      isReadOnly: this.isReadOnly
    } = options);


    // There is duplicates getting into here -- so we should figure out how to not allow those to happen
    // maybe we should figure out how the items are being iterated and sliced up, since it looks like it iterates over
    // all the slices right now to figure this out and we might not even need to map out into components, since
    // we can ust reset based on something else
    const getReenforcementOnlyObjectives = function(availableComponents) {
      const availableComponentsFlattened = _.flatten(availableComponents);
      const introObjectivesLowestFirst = new GuidedLearningObjectiveList(availableComponentsFlattened, {parse: true});

      const toBeRemoved = introObjectivesLowestFirst.getReinforcementOnlyObjectives();

      return _.chain(toBeRemoved)
        .compact()
        .value();
    };

    const availableObjectiveComponentsBlobs = this.availSortedObjectives.nonMilestoneObjectives;
    this.availableObjectiveComponents = GLItemViewHelpers.makeListsWithJsonObjectives(availableObjectiveComponentsBlobs);

    const unavailableObjectiveComponentsBlobs = this.model.getUnavailableObjectives();
    this.unavailableObjectiveComponents = GLItemViewHelpers.makeListsWithJsonObjectives(unavailableObjectiveComponentsBlobs);

    // These are next priority tier items
    const requireWorkObjectiveComponentsBlobs = this.model.hasStartableItems()
      ? getReenforcementOnlyObjectives(availableObjectiveComponentsBlobs) : [];

    // Need to filter out the items that require work since they do not belong in this list logically
    // and then map them to their own components to get things sorted out
    this.availableObjectiveComponents.forEach((activeComponentItem) => {
      const requireWorkIds = _.uniq(requireWorkObjectiveComponentsBlobs.map((item) => {
        return item.id;
      }));
      const notInList = (item) => {
        return !(Array.from(requireWorkIds).includes(item.id));
      };
      const activeComponent = activeComponentItem;
      activeComponentItem.reset(activeComponent.filter(notInList));
    });

    // Milestones
    this.milestoneCollection = this.availSortedObjectives.milestones;
    this.pathContainsMilestones = this.milestoneCollection.length;
    const eventsBlob = this.availSortedObjectives.events;
    this.eventsObjectiveComponents = GLItemViewHelpers.makeListsWithJsonObjectives(eventsBlob);

    this.thumbnailImage = this.model.getThumbnailImage();
    this.description = this.model.getDescription();

    this._setVisualConnectionStatus();

    const lastMilestone = this.milestoneCollection.last();
    if (lastMilestone) {
      this.listenTo(lastMilestone, 'change', () => {
        this._setVisualConnectionStatus();
      });
    }
  }

  templateHelpers() {
    return {
      completed: this.model.getComputedCompletedCount(),
      available: this.model.getComputedAvailableCount(),
      categoryName: this.model.getName(),
      isSelfDirected: false, // if this is omitted, the template engine crashes
      isEnrolled: this.model.getEnrollmentStatus()
    };
  }

  onRender() {
    if (!this.model.getEnrollmentStatus()) {
      this.$el.addClass('no-enrollment');
    } else {
      this.$el.removeClass('no-enrollment');
    }
    this._renderAvailableCollection();
    this._renderUnavailableCollection();

    if (this.pathContainsMilestones) {
      this._renderMilestoneLayout(!this.model.getEnrollmentStatus());
      this._adjustContentWidth();
    }

    if (this.eventsObjectiveComponents.length >= 0) {
      this._renderEvents();
    }

    if (env.settings.app === AxonifyApps.TRAINING
      && TenantPropertyProvider.get().getProperty('enableFastTrack')
      && this.model.hasFastTrackObjective()
    ) {
      this._renderFastTrackBanner();
    }
  }

  onAttach() {
    this.$('.progress-container').trigger('focus');

    this._trySetupActionBar();
  }

  onDestroy() {
    this._tryHideActionBar();
  }

  scrollToFirstButtonAvailable() {
    if (this.scrollToFirstDisabled) {
      return false;
    }

    // There's assumed to be some kind of button on the page, this will degrade gracefully if there is not one
    // so no need to over-engineer this check and expose a bunch of boilerplate API(s) to do it through Marionette
    const $firstAvailableObjective = this._getFirstAvailableObjective();

    if ($firstAvailableObjective.length > 0) {
      this.scrollToFirstDisabled = true;

      setTimeout(() => {
        if (this.isDestroyed) {
          return;
        }

        const $objectivesWrapper = $firstAvailableObjective.closest('.js-learning-objectives');

        Scrollable.scrollIntoView(this, $objectivesWrapper, {
          animate: {
            duration: 'normal',
            complete: () => {
              if (this.isDestroyed) {
                return;
              }

              $objectivesWrapper.addClass('card--interactive--highlighted');

              const dismissHover = () => {
                if (this.isDestroyed) {
                  return;
                }

                $objectivesWrapper.removeClass('card--interactive--highlighted');
                this.scrollToFirstDisabled = false;
              }

              if ($os.mobile) {
                setTimeout(dismissHover, HOVER_EFFECT_DELAY);
              } else {
                $objectivesWrapper.on('mouseout', dismissHover);
              }
            }
          }
        });
      }, SCROLL_DELAY);

      return true;
    }

    return false;
  }

  _getFirstAvailableObjective() {
    return this.$('.axon-button, .ax-button')
      .filter(':visible:not(.off-screen):not(.hidden)')
      .first();
  }

  _setVisualConnectionStatus() {
    GLItemViewHelpers.setVisualConnectionStatusForAvailableComponents({
      availableObjectiveComponents: this.availableObjectiveComponents,
      eventObjectiveComponents: this.eventsObjectiveComponents,
      milestoneCollection: this.milestoneCollection
    });
    GLItemViewHelpers.setVisualConnectionStatusForEvents({
      eventObjectiveComponents: this.eventsObjectiveComponents,
      availableObjectiveComponents: this.availableObjectiveComponents,
      milestoneCollection: this.milestoneCollection
    });
    GLItemViewHelpers.setVisualConnectionStatusForObjectiveComponents(this.unavailableObjectiveComponents);
  }

  _adjustContentWidth() {
    this.ui.overviewHeader.addClass('milestone-content');
    this.ui.descriptionWrapper.addClass('milestone-content');
  }

  _renderAvailableCollection(disabled) {
    const collectionView = GLItemViewHelpers.getCollectionView( this.availableObjectiveComponents, {
      ...this._getChildViewOptions(disabled),
      programId: this.model.get('id') || this.model.programId
    });
    this.availableContainer.show(collectionView);
  }

  _renderUnavailableCollection() {
    const collectionView = GLItemViewHelpers.getCollectionView(
      this.unavailableObjectiveComponents,
      {
        objectiveValidator: this.objectiveValidator,
        objectiveStarter: this.objectiveStarter,
        viewTopicDrillDown: this.viewTopicDrillDown,
        disabled: true,
        forceLockedAppearance: true,
        programId: this.model.get('id') || this.model.programId
      }
    );
    this.unavailableContainer.show(collectionView);
    if (this.unavailableObjectiveComponents.length === 0) {
      this.ui.bannerWrapper.addClass('hidden');
    }
  }

  _renderMilestoneLayout(disabled) {
    const childViewOptions = this._getChildViewOptions(disabled);
    const milestonePathView = new Marionette.CollectionView({
      collection: this.milestoneCollection,
      childView: MilestoneItemView,
      childViewOptions: (model, milestoneIndex) => {
        const prereqsList = GLItemViewHelpers.makeListsWithJsonObjectives(model.getPrereqs());

        const setVisualConnectionStatusForMilestones = () => {
          GLItemViewHelpers.setVisualConnectionStatusForMilestones({
            prereqsList,
            milestoneInterfaceModel: model,
            milestoneCollection: this.milestoneCollection,
            milestoneIndex,
            followingObjectiveComponents: this.availableObjectiveComponents.length ? this.availableObjectiveComponents : this.eventsObjectiveComponents,
            milestoneModel: model
          });
        };

        setVisualConnectionStatusForMilestones();

        this.listenTo(model, 'change', () => {
          setVisualConnectionStatusForMilestones();
        });

        childViewOptions.index = milestoneIndex;
        childViewOptions.prereqsList = prereqsList;
        return childViewOptions;
      }
    });
    this.milestonesRegion.show(milestonePathView);
  }

  _renderEvents(disabled) {
    const eventsView = GLItemViewHelpers.getCollectionView(this.eventsObjectiveComponents, this._getChildViewOptions(disabled));
    this.eventsRegion.show(eventsView);
  }

  _renderFastTrackBanner() {
    const fastTrackBanner = ViewControllerFactory.createLegacyView(ReactControllerDefinitionFactory({
      component: import('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewFastTrackBanner'),
      props: {
        onShowHowToModal: () => {
          const dialog = ViewControllerFactory.createLegacyView(ReactControllerDefinitionFactory({
            component: import('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewFastTrackHowToModal')
          }));
          this.fastTrackDialogRegion.show(dialog);
        }
      }
    }));

    this.fastTrackBannerRegion.show(fastTrackBanner);
  }

  _getChildViewOptions(disabled) {
    const enableObjectiveInteraction = !this.isReadOnly && (!this.isSelfDirected || this.isEnrolled);

    return {
      objectiveValidator: this.objectiveValidator,
      objectiveStarter: this.objectiveStarter,
      viewTopicDrillDown: this.viewTopicDrillDown,
      disabled: disabled || !enableObjectiveInteraction,
      programId: this.model.get('id') || this.model.programId
    };
  }

  _shouldShowActionBar() {
    return !this.isReadOnly && this._getFirstAvailableObjective().length !== 0;
  }

  _trySetupActionBar() {
    if (!this._shouldShowActionBar()) {
      return;
    }

    window?.app?.layout?.actionBar?.setActionButton({
      type: ActionBarType.CUSTOM_TEXT,
      text: I18n.t('GuidedLearning.jumpToNextItem'),
      onClick: this.scrollToFirstButtonAvailable.bind(this)
    });

    this._actionBarShown = true;
  }

  _tryHideActionBar() {
    if (this._actionBarShown) {
      window?.app?.layout?.actionBar?.setActionButton(null);
    }
  }
}

module.exports = ProgramOverviewPathContentView;
