import I18n from '@common/libs/I18n';
import {
  MobileStepper as MuiMobileStepper,
  type MobileStepperProps as MuiMobileStepperProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

export type AxMobileStepperProps = MuiMobileStepperProps;

const styledOptions = {
  name: 'AxMobileStepper'
};

const StyledAxMobileStepper = styled(MuiMobileStepper, styledOptions)<AxMobileStepperProps>(() => {
  return {};
});

/**
 * A wrapper around the MUI `MobileStepper` component
 *
 * ### Links
 * - [MUI API](https://mui.com/material-ui/api/mobile-stepper/)
 */
export const AxMobileStepper = forwardRef((props: AxMobileStepperProps, ref: Ref<HTMLDivElement>) => {
  const {
    LinearProgressProps,
    ...otherProps
  } = props;

  const mobileStepperProps: AxMobileStepperProps = {
    ...otherProps,
    LinearProgressProps: {
      'aria-label': I18n.t('general.currentCompletion'),
      ...LinearProgressProps
    }
  };

  return (
    <StyledAxMobileStepper
      { ...mobileStepperProps }
      ref={ ref }
    />
  );
});

export default AxMobileStepper;
