const Backbone = require('Backbone');

const ObjectiveFactory = require('@common/data/models/objectives/ObjectiveFactory');
const {
  TOPIC_OBJECTIVE,
  TASK_OBJECTIVE,
  CERTIFICATION_OBJECTIVE,
  EVENT_OBJECTIVE,
  EVALUATION_OBJECTIVE
} = require('@common/data/models/objectives/GuidedLearningObjectiveType');

const ITEM_IS_STARTABLE = (guidedLearningObjective) => {
  return guidedLearningObjective.isStartable();
};

class GuidedLearningObjectiveList extends Backbone.Collection {
  model(attrs, options) {
    return ObjectiveFactory(attrs, options);
  }

  isLocked(learningObjective) {
    const isLocked = learningObjective.isLocked();

    // Why go through this trouble? There might be other logic here (and there used to once be)
    // so we leave this collection abstraction in place
    return isLocked;
  }

  getReinforcementOnlyObjectives() {
    const isTopicObjectiveItem = (objective) => {
      return objective.getType() === TOPIC_OBJECTIVE;
    };
    const isReinforcementOnlyItem = (objective) => {
      return !objective.isIntro();
    };

    return this.models
      .slice()
      .filter(isTopicObjectiveItem)
      .filter(isReinforcementOnlyItem);
  }

  getNextStartableItem() {
    return this.find(ITEM_IS_STARTABLE);
  }

  containsInteractiveObjective() {
    return this.models.some((model) => {
      const isLocked = model.isLocked();
      const type = model.getType();
      const isTask = type === TASK_OBJECTIVE;
      const isTopic = type === TOPIC_OBJECTIVE;
      const isCertification = type === CERTIFICATION_OBJECTIVE;
      const isEvaluation = type === EVALUATION_OBJECTIVE;
      const isImported = isEvaluation ? model.isImported() : false;
      const hasAction = Boolean(model.getActionableItem());
      const isEvent = type === EVENT_OBJECTIVE;
      const eventCanDrillDown = isEvent ? model.canDrillDown() : false;

      return (
        !isLocked && (isTopic || isTask || isCertification || (isEvaluation && !isImported) || hasAction || (isEvent && eventCanDrillDown))
      );
    });
  }
}

module.exports = GuidedLearningObjectiveList;
