const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'START_CERT',
  'VISIT_PATH',
  'START',
  'RESTART', // incomplete, restart the topicLevel,
  'RETAKE', // completed,  take the topicLevel again,
  'RESUME',
  'RESUME_FAST_TRACK',
  'QUICK_QUIZ',
  'NONE',
  'LOCKED',
  'VIEW_ALL'
]);
