import { type QAClassName } from '@common/interfaces/SharedTypes';
import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import {
  AxTypography,
  type AxTypographyProps
} from '@common/modules/react/themes/components/data-display';
import { type PropsWithChildren } from 'react';

type AxDialogDefaultTitleProps = PropsWithChildren<AxTypographyProps & {
  qaClassName: QAClassName
}>;

export default function AxDialogDefaultTitle(props: AxDialogDefaultTitleProps ): JSX.Element {
  const {
    children,
    qaClassName,
    className,
    ...typographyProps
  } = props;

  return <AxTypography
    variant='h2'
    className={ joinClassNames(qaClassName, className) }
    { ...typographyProps }
  >
    { children }
  </AxTypography>;
}
