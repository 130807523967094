const { Model } = require('Backbone');
const {
  isObject,
  pick
} = require('underscore');

const AssessmentType = require('@common/data/enums/AssessmentType');

const FIELDS_TO_MONITOR = ['program', 'totalPointsEarnable', 'attemptsRemaining'];

class AssessmentExamOption extends Model {
  static FIELDS = {
    FOR_ASSESSMENT_TYPE: 'forAssessmentType'
  }

  defaults() {
    return {
      type: 'ExamOption',
      pointsEarnable: 0,
      totalPointsEarnable: 0,
      attemptsRemaining: 0,
      locked: false
    };
  }

  constructor(...args) {
    super(...args);

    this.on('change:lastRelevantResult', (model, lastRelevantResult) => {
      this.extractFieldsFromResult(lastRelevantResult);
    });

    this.extractFieldsFromResult(this.get('lastRelevantResult'));
  }

  extractFieldsFromResult(lastRelevantResult) {
    if (isObject(lastRelevantResult)) {
      lastRelevantResult.type = AssessmentType.FormalExamTraining;
      this.set(pick(lastRelevantResult, FIELDS_TO_MONITOR));
    }
  }

  setLaunchContext(launchContext) {
    this.set('launchContext', launchContext);
  }

  hasFailedLastAttempt() {
    const lastRelevantResult = this.get('lastRelevantResult');
    return Boolean(lastRelevantResult) && !lastRelevantResult.passed;
  }

  hasPassedLastAttempt() {
    const lastRelevantResult = this.get('lastRelevantResult');
    return Boolean(lastRelevantResult) && lastRelevantResult.passed;
  }

  hasAttempt() {
    return Boolean(this.get('lastRelevantResult'));
  }

  hasAttemptsRemaining() {
    return this.get('attemptsRemaining') > 0;
  }

  doesSupportGrading() {
    return true;
  }

  isLocked() {
    return this.get('locked') === true;
  }

  isRetake() {
    if (AssessmentType.supportsHistoricalRetake(this.getForAssessmentType()) && this.hasAttempt()) {
      return this.hasFailedLastAttempt() && this.hasAttemptsRemaining();
    }

    return this.get('isRetake');
  }

  getAssessmentType() {
    const lastRelevantResult = this.get('lastRelevantResult');

    // We check the server first and then fallback to local
    return lastRelevantResult ? lastRelevantResult.type : this.getForAssessmentType();
  }

  setForAssessmentType(type) {
    return this.set(AssessmentExamOption.FIELDS.FOR_ASSESSMENT_TYPE, AssessmentType.assertLegalValue(type));
  }

  getForAssessmentType() {
    return this.get(AssessmentExamOption.FIELDS.FOR_ASSESSMENT_TYPE);
  }

  getLaunchContext() {
    return this.get('launchContext');
  }

  getProgramId() {
    return this.get('program').id;
  }

  isAssessmentFastTrackEligible() {
    return false;
  }

  toAssessmentRequestJson() {
    return {
      programId: this.getProgramId(),
      launchContext: this.getLaunchContext(),
      retake: this.isRetake()
    };
  }
}

module.exports = AssessmentExamOption;
