import I18n from '@common/libs/I18n';
import {
  CircularProgress as MuiCircularProgress,
  styled,
  type CircularProgressProps as MuiCircularProgressProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxCircularProgressProps = Omit<MuiCircularProgressProps, 'variant' | 'thickness' | 'size' | 'disableShrink'> & {
    /**
   * The value of the progress indicator for the determinate variant.
   * Pre-defined values: 'light', 'medium', 'heavy'
   * Fine tune with a number between 0 and 100.
   */
  thickness?: number | 'light' | 'medium' | 'heavy';
  /**
   * The size of the component.
   * Pre-defined values: 'small', 'medium', 'big'
   * If using a number, the pixel unit is assumed.
   * If using a string, you need to provide the CSS unit, e.g '3rem'.
   */
  size?: number | string | 'small' | 'medium' | 'big';
  /**
   * If `true`, the shrink animation is disabled.
   */
  disableShrink?: boolean;
};
declare module '@mui/material/CircularProgress' {
  export interface CircularProgressPropsColorOverrides {
    'positive': true
    'warning': true
    'negative': true
    'informational': true
    'inherit': true
    
    'primary': false
    'secondary': false
    'error': false
    'info': false
    'success': false
  }
}

const styledOptions = {
  name: 'AxCircularProgress'
};

/**
 * A spinner that displays while a task is being performed.
 */
const StyledAxCircularProgress = styled(MuiCircularProgress, styledOptions)<AxCircularProgressProps>(() => {
  return {};
}) as React.FC<AxCircularProgressProps>;

export const AxCircularProgress = forwardRef(({
  thickness = 'medium',
  size = 'medium',
  ...otherProps
}: AxCircularProgressProps, ref: Ref<HTMLDivElement>) => {
  
  const circularProgressProps: AxCircularProgressProps = {
    thickness,
    size,
    'aria-label': otherProps['aria-label'] || I18n.t('general.loadingData'),
    ...otherProps
  };

  modifyCircularProgressProps(circularProgressProps);
  
  return (
    <StyledAxCircularProgress { ...circularProgressProps } ref={ ref }/>
  );
});

export default AxCircularProgress;


const modifyCircularProgressProps = (props: AxCircularProgressProps): AxCircularProgressProps => {
  if (props.thickness === 'light') {
    props.thickness = 2;
  }
  
  if (props.thickness === 'medium') {
    props.thickness = 4;
  }
  
  if (props.thickness === 'heavy') {
    props.thickness = 10;
  }

  if (props.size === 'small') {
    props.size = 20;
  }

  if (props.size === 'medium') {
    props.size = 40;
  }

  if (props.size === 'big') {
    props.size = 80;
  }

  return props;
};
