const NextStartableAssessmentProvider = require('./NextStartableAssessmentProvider');

class QuizboardNextStartableAssessmentProvider extends NextStartableAssessmentProvider {
  constructor(assessmentOptionList, ...args) {
    super(...args);

    this.assessmentOptionList = assessmentOptionList;
    if (!(assessmentOptionList)) {
      throw new Error('You must provide a legal assessment option list in order to use this provider');
    }
  }

  canProduceNext() {
    return true;
  }

  getNext() {
    const findItemsFromListLocally = () => {
      const nextUnfinishedOption = this.assessmentOptionList.getNextUnfinishedItem();

      if (nextUnfinishedOption) {
        nextUnfinishedOption.setForAssessmentType(
          this.assessmentOptionList.getAssessmentType()
        );
      }

      return Promise.resolve(nextUnfinishedOption);
    };

    return Promise.resolve(
      this.assessmentOptionList.fetch()
    ).then(findItemsFromListLocally);
  }
}

module.exports = QuizboardNextStartableAssessmentProvider;
