const { Model } = require('Backbone');
const {
  isObject,
  pick
} = require('underscore');

const AssessmentType = require('@common/data/enums/AssessmentType');

const FIELDS_TO_MONITOR = ['category', 'subject', 'topic', 'level', 'pointsForPass'];

class AssessmentTopicOption extends Model {
  static FIELDS = {
    FOR_ASSESSMENT_TYPE: 'forAssessmentType'
  }

  defaults() {
    return {
      type: 'TopicOption',
      pointsForPass: 0,
      isRetake: false
    };
  }

  constructor(attrs, options) {
    super(attrs, options);

    this.on('change:lastRelevantResult', (model, lastRelevantResult) => {
      this.extractFieldsFromResult(lastRelevantResult);
      this.setDefaultsForPass(lastRelevantResult);
    });

    this.extractFieldsFromResult(this.get('lastRelevantResult'));
    this.setDefaultsForPass(this.get('lastRelevantResult'));
  }

  extractFieldsFromResult(lastRelevantResult) {
    if (isObject(lastRelevantResult)) {
      this.set(pick(lastRelevantResult, FIELDS_TO_MONITOR));
    }
  }

  setDefaultsForPass(lastRelevantResult) {
    // This sets this to true -- I think the right thing to do here is make this thing a model and set it on the defaults
    if (isObject(lastRelevantResult)) {
      lastRelevantResult.passed = lastRelevantResult.passed != null ? lastRelevantResult.passed : true;
    }
  }

  setLaunchContext(launchContext) {
    this.set('launchContext', launchContext);
  }

  hasFailedLastAttempt() {
    const lastRelevantResult = this.get('lastRelevantResult');

    return Boolean(lastRelevantResult) && (lastRelevantResult.passed === false);
  }

  isLastAttemptFastTrack() {
    const lastRelevantResult = this.get('lastRelevantResult');

    return Boolean(lastRelevantResult?.fastTrack);
  }

  hasPassedLastAttempt() {
    const lastRelevantResult = this.get('lastRelevantResult');

    return Boolean(lastRelevantResult) && (lastRelevantResult.passed === true);
  }

  hasAttempt() {
    return Boolean(this.get('lastRelevantResult'));
  }

  doesSupportGrading() {
    return this.getAssessmentType() !== AssessmentType.ExtraTraining;
  }

  isRetake() {
    if (AssessmentType.supportsHistoricalRetake(this.getForAssessmentType()) && this.hasAttempt()) {
      // If the last attempt failed and it was a fast track, the user cannot retake the assessment
      // The only option avaible should be to start the full topic
      return this.hasFailedLastAttempt() && !this.isLastAttemptFastTrack();
    }

    return this.get('isRetake');
  }

  getAssessmentType() {
    const lastRelevantResult = this.get('lastRelevantResult') || {};

    // We check the server first and then fallback to local
    return lastRelevantResult.type || this.getForAssessmentType();
  }

  isLocked() {
    return this.get('locked') === true;
  }

  setForAssessmentType(type) {
    return this.set(AssessmentTopicOption.FIELDS.FOR_ASSESSMENT_TYPE, AssessmentType.assertLegalValue(type));
  }

  getForAssessmentType() {
    return this.get(AssessmentTopicOption.FIELDS.FOR_ASSESSMENT_TYPE);
  }

  getProgramId() {
    const lastRelevantResult = this.get('lastRelevantResult');

    if (lastRelevantResult && lastRelevantResult.program) {
      return lastRelevantResult.program.id;
    }

    return this.get('programId');
  }

  getTopicId() {
    const topic = this.get('topic');
    return topic && topic.id;
  }

  getTopicLevel() {
    return this.get('level');
  }

  getCourse() {
    return this.get('course');
  }

  getLaunchContext() {
    return this.get('launchContext');
  }

  getAssessmentReason() {
    if (this.isRetake()) {
      return this.get('assessmentReason');
    }

    return undefined;
  }

  getFastTrack() {
    return this.get('fastTrack');
  }

  isAssessmentFastTrackEligible() {
    return this.get('fastTrackEligible') || false;
  }

  toAssessmentRequestJson() {
    const json = {
      topicId: this.getTopicId(),
      level: this.getTopicLevel(),
      course: this.getCourse(),
      launchContext: this.getLaunchContext(),
      retake: this.isRetake(),
      assessmentReason: this.getAssessmentReason(),
      fastTrack: this.getFastTrack(),

      // The server sends -1 for topic options -- so we should only send it if we can find it and it's greater than 0 since
      // it complains otherwise
      programId: (this.getProgramId() > -1) ? this.getProgramId() : undefined
    };

    if (this.getForAssessmentType() === AssessmentType.ExtraTraining && this.has('isPopQuiz')) {
      json.popQuiz = this.get('isPopQuiz');
    }

    return json;
  }
}

module.exports = AssessmentTopicOption;
