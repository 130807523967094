// Module
var code = `<div class="progress-wrapper ax-grid__col--auto-size ax-grid ax-grid--no-gutter">
  <div class="topic-level__row--item user-score ax-grid__col--auto-size"><%- progressScore %></div>
  <div class="topic-level__row--item progress icon-<%- progressIcon %> ax-grid__col--auto-size">
    <p class="off-screen"><%- progressIconLabel %></p>
  </div>
</div>
<div class="topic-level__row--item title-container">
  <div class="title-container__level"><%- t('knowledge.level', {level: level}) %></div>
  <span <%= badgeIcon.class ? 'role="img"' : '' %> class="topic-level__row--badge icon-<%- badgeIcon.class %>" aria-label="<%- badgeIcon.ariaLabel %>"></span>
  <div class="title-container__meta"></div>
  <div class="topic-level__row--item locked-wrapper ax-grid ax-grid__col--auto-size">
    <div class="locked-wrapper__label ax-grid ax-grid__col--auto-size">
      <div class="icon-lock ax-grid__col--auto-size" aria-hidden="true"></div>
      <p class="lock-text ax-grid__col--auto-size"><%- t('general.locked') %></p>
      <p class="pass-info ax-grid__col--auto-size"><%- t('selfDirected.topicDetails.topicLevels.passInfo', {levelNum: blockingLevelNum}) %></p>
    </div>
  </div>
</div>
<div class="topic-level__row--item ax-grid ax-grid__col--auto-size ax-grid--no-gutter button-wrapper">
  <div class="print-certifications-button-wrapper"></div>
  <%= axButton({
    label: previewButtonText,
    className: 'js-preview-button topic-level__row--item ax-grid__col--auto-size',
    ariaLabel: previewButtonText
  }) %>
  <%= axButton({
    label: actionButtonText,
    className: 'js-action-button topic-level__row--item ax-grid__col--auto-size',
    ariaLabel: actionButtonText
  }) %>
  <%= axButton({
    label: t('fastTrack.fastTrackTitle'),
    branded: true,
    className: 'js-fast-track-button topic-level__row--item ax-grid__col--auto-size',
    ariaLabel: t('fastTrack.fastTrackTitle')
  }) %>
</div>
`;
// Exports
module.exports = code;