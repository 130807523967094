// Module
var code = `<% if (primaryButtonDefaultColour) { %>
  :root {
    --primary-button-default-colour:<%- primaryButtonDefaultColour %>;
    --axButton-branded-background: <%- primaryButtonDefaultColour %>;
  }
<% } %>

<% if (primaryButtonDefaultHoverColour) { %>
  :root {
    --axButton-branded-background-hover: <%- primaryButtonDefaultHoverColour %>;
  }
<% } %>

<% if (primaryButtonTextColour) { %>
  :root {
    --axButton-branded-color: <%- primaryButtonTextColour %>;
  }
<% } %>

<% if (primaryButtonHoverTextColour) { %>
  :root {
    --axButton-branded-color-hover: <%- primaryButtonHoverTextColour %>;
  }
<% } %>
`;
// Exports
module.exports = code;