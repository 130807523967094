const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const {
  START,
  RETAKE,
  LOCKED,
  VIEW,
  VIEW_INTERACTIVE,
  ENROLL,
  ENROLL_INTERACTIVE,
  NONE,
  RESUME,
  REASSIGN,
  RESUME_FAST_TRACK
} = require('@common/components/learningObjectives/LearningObjectiveCardActionType');

const AxonifyApps = require('@common/data/enums/AxonifyApps').default;

class ObjectiveCardActionView extends Marionette.ItemView {
  initialize(options) {
    ({
      startObjectiveCallback: this.startObjectiveCallback,
      programId: this.programId,
      isFastTrackEligible: this.isFastTrackEligible
    } = options);
  }

  getTemplate() {
    return `
      <%= axButton({
        className: 'js-off-screen-view-button off-screen ' + offscreenViewButtonCssClass,
        label: offscreenViewButtonText,
        ariaLabel: offscreenViewButtonLabel,
        size: 'm'
      }) %>
      <div class="learning-objective-card__action-buttons">
        <%= axButton({
          branded: !isFastTrack,
          className: 'qa-action-button js-action-button ' + actionButtonCssClass,
          label: actionButtonText,
          ariaLabel: actionButtonLabel,
          size: 'm'
        }) %>
        <% if (isFastTrack) { %>
          <%= axButton({
            branded: true,
            className: 'qa-fast-track-button js-fast-track-button',
            label: fastTrackButtonText,
            ariaLabel: fastTrackButtonLabel,
            size: 'm'
          }) %>
        <% } %>
      </div>
    `;
  }

  className() {
    return 'learning-objective-card__action';
  }

  events() {
    return {
      'click .js-action-button': (e) => {
        this._onButtonClick(e);
      },
      'click .js-fast-track-button': (e) => {
        this._onButtonClick(e, true);
      }
    };
  }

  templateHelpers() {
    return this._getButtonConfig();
  }

  _onButtonClick(e, fastTrack = false) {
    if (this.startObjectiveCallback) {
      e.stopPropagation();
      this.startObjectiveCallback(fastTrack);
    }
  }

  _getButtonConfig() {
    let actionType = this.getOption('actionType');
    const name = this.getOption('name');

    let actionButtonText = '';
    let actionButtonLabel = '';
    let actionButtonCssClass = '';
    const offscreenViewButtonText = I18n.t('start.startButton.view');
    const offscreenViewButtonLabel = I18n.t('start.startButtonAriaLabel.view', {name});
    const fastTrackButtonText = I18n.t('fastTrack.fastTrackTitle');
    const fastTrackButtonLabel = I18n.t('fastTrack.fastTrackAriaLabel', {name});
    let offscreenViewButtonCssClass = '';

    // Server already handles this for manager
    // If user is coming from start/hub don't hide buttons
    if (env.settings.app !== AxonifyApps.MANAGER && this.getOption('isSelectableTraining') == null) {
      actionType = this._shouldShowStartTrainingButtons(actionType);
    }

    const isFastTrack = this.isFastTrackEligible && actionType === START;

    //The offscreen view button equivalent to a card click is only required when card is clickable, and action is not view
    switch (actionType) {
      case LOCKED:
        offscreenViewButtonCssClass = 'hidden';
        actionButtonCssClass = 'hidden';
        break;
      case RESUME:
        actionButtonText = I18n.t('start.startButton.resume');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.resume', {name});
        break;

      case RESUME_FAST_TRACK:
        actionButtonText = I18n.t('start.startButton.resumeFastTrack');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.resumeFastTrack', {name});
        break;

      case RETAKE:
        actionButtonText = I18n.t('start.startButton.retake');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.retake', {name});
        break;

      case START:
        actionButtonText = I18n.t('start.startButton.start');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.start', {name});
        break;

      case ENROLL:
        actionButtonText = I18n.t('start.startButton.view');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.view', {name});
        offscreenViewButtonCssClass = 'hidden';
        break;

      case ENROLL_INTERACTIVE:
        actionButtonText = I18n.t('start.startButton.view');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.view', {name});
        actionButtonCssClass = 'off-screen';
        offscreenViewButtonCssClass = 'hidden';
        break;

      case VIEW:
        actionButtonText = I18n.t('start.startButton.viewTask');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.viewTask', {name});
        offscreenViewButtonCssClass = 'hidden';
        break;

      case VIEW_INTERACTIVE:
        actionButtonText = I18n.t('start.startButton.viewTask');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.viewTask', {name});
        actionButtonCssClass = 'off-screen';
        offscreenViewButtonCssClass = 'hidden';
        break;

      case REASSIGN:
        actionButtonText = I18n.t('start.startButton.reassign');
        actionButtonLabel = I18n.t('start.startButtonAriaLabel.reassign', {name});
        actionButtonCssClass = 'reassign-button qa-reassign-button';
        break;

      case NONE:
      default:
        actionButtonCssClass = 'hidden';
    }

    return {
      actionButtonText,
      actionButtonLabel,
      actionButtonCssClass,
      offscreenViewButtonText,
      offscreenViewButtonLabel,
      offscreenViewButtonCssClass,
      isFastTrack,
      fastTrackButtonText,
      fastTrackButtonLabel
    };
  }

  _shouldShowStartTrainingButtons(actionType) {
    let actionTypeResult = actionType;

    const interactiveActionTypes = [RESUME, RETAKE, START, ENROLL, ENROLL_INTERACTIVE];
    const isActionTypeInteractive = interactiveActionTypes.includes(actionTypeResult);
    const isMinDailyTrainingFulfilled = app.sessionController.isMinDailyTrainingFulfilled();
    const isCurrentlyAssignedAssessment = this._isCurrentlyAssignedAssessment();

    // Should hide action buttons that start/resume assessment before min training is completed
    // Unless it's currently assigned assessment
    if (!isMinDailyTrainingFulfilled && !isCurrentlyAssignedAssessment && isActionTypeInteractive) {
      actionTypeResult = NONE;
    }

    return actionTypeResult;
  }

  _isCurrentlyAssignedAssessment() {
    const currentAssessmentOptionsList = app.sessionController.session.getCurrentAssessmentOptionsList();

    if (currentAssessmentOptionsList) {
      // Iterate through the list and check each item's programId
      for (const currentAssessmentOptionModel of currentAssessmentOptionsList.models) {
        const assessment = currentAssessmentOptionModel.get('nextItem');
        if (assessment && this.programId === assessment.get('programId')) {
          // If a matching assessment is found, return true, otherwise return false below
          return true;
        }
      }
    }

    return false;
  }
}

module.exports = ObjectiveCardActionView;
