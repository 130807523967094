// Module
var code = `<div class="js-fast-track-banner-region"></div>
<h2 class="path-overview__title"><%- t('GuidedLearning.contentHeader') %></h2>
<div class="milestones-wrapper program-content"></div>
<div class="available-wrapper categories-wrapper"></div>
<div class="events-wrapper program-content"></div>

<div class="unavailable-banner-wrapper">
  <h2><%- t('GuidedLearning.unlockWithDailyTraining') %></h2>
  <div class="unavailable-wrapper categories-wrapper"></div>
</div>

<div class="js-fast-track-how-to__dialog-region hidden"></div>
`;
// Exports
module.exports = code;