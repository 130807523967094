const UIKit = require('@training/widgets/UIKit');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const CertificationStatus = require('@training/apps/training/enums/CertificationStatus');
const TopicLevelActionType = require('@common/data/enums/TopicLevelActionType');
const TopicProgressEnum = require('@common/data/enums/TopicProgressEnum');
const TopicCTAButtonHelper = require('@common/components/search/TopicCTAButtonHelper');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const TrainingModulePreviewView = require('@training/apps/topics/TrainingModulePreviewView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const {
  getPreviewButtonText,
  allowDirectPreview
} = require('@training/apps/topics/TopicPreviewHelper');

const { indexOf } = require('underscore');

class TopicLevelRowView extends Marionette.LayoutView {
  initialize(options = {}) {
    super.initialize(options);

    ({
      startTrainingFn: this.startTrainingFn
    } = options);
  }

  className() {
    return 'topic-level__row ax-grid ax-grid--align-items-center ax-grid--no-gutter';
  }

  getTemplate() {
    return require('./TopicLevelRowView.html');
  }

  templateHelpers() {
    return {
      progressScore: this._getProgressScore(),
      progressIcon: this._getProgressIcon(),
      progressIconLabel: this._getProgressIconLabel(),
      badgeIcon: this._getBadgeIcon(),
      actionButtonText: this._getActionButtonText(),
      previewButtonText: getPreviewButtonText(this.model.get('trainingModuleType')),
      blockingLevelNum: this._getBlockingLevelNum()
    };
  }

  ui() {
    return {
      actionButton: '.js-action-button',
      fastTrackButton: '.js-fast-track-button',
      previewButton: '.js-preview-button',
      lockedWrapper: '.locked-wrapper'
    };
  }

  regions() {
    return {
      metaRegion: '.title-container__meta'
    };
  }

  events() {
    return {
      'click @ui.actionButton': this.onActionButtonClick,
      'click @ui.fastTrackButton': this.onFastTrackButtonClick,
      'click @ui.previewButton': this.onPreviewButtonClick
    };
  }

  onRender() {
    const action = this.model.get('action');

    if (action.isActionable) {
      this._showActionButton();
    }

    this._showPreviewButton();
  }

  onActionButtonClick() {
    this.startTrainingFn(this.model.get('action'));
  }

  onFastTrackButtonClick() {
    const action = this.model.get('action');
    Object.assign(action, { fastTrack: true });
    this.startTrainingFn(action);
  }

  onPreviewButtonClick() {
    if (allowDirectPreview(this.model.get('trainingModuleType'))) {
      this._confirmPreview()
    } else {
      const modalView = new AccessibleModalView({
        id: 'modalview',
        className: 'modal confirm-dialog-view modal--s'
      });

      const modalChildView = new ConfirmDialogView({
        confirmCallback: this._confirmPreview,
        model: this.model,
        title: I18n.t('trainingPreview.videoConfirmationModal.title'),
        confirmationText: I18n.t('trainingPreview.videoConfirmationModal.description'),
        buttonText: I18n.t('general.continue'),
        buttonColorClassName: 'default'
      });

      window.app.layout.presentModal(modalView, { closeClick: true });
      modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

      this.listenToOnce(modalChildView, 'destroy', () => {
        window.app.layout.dismissModal();
      });
    }
  }

  _confirmPreview() {
    window.app.layout.dismissModal();

    const modalView = new AccessibleModalView({
      id: 'modalView',
      className: 'modal training-preview-modal modal--s'
    })

    const modalChildView = new TrainingModulePreviewView({ trainingModule: this.model.get('trainingModule') });

    window.app.layout.presentModal(modalView, { closeClick: true });
    modalView.setSubviewIn(modalChildView);

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _showActionButton() {
    const action = this.model.get('action');
    const session = window.app.sessionController.session;

    if (action.actionType !== TopicLevelActionType.NONE) {
      if (this.model.get('isLocked')) {
        this.ui.lockedWrapper.show();
      } else if (TopicCTAButtonHelper.isStartable(action, session)) {
        this.ui.actionButton.show();
        this._showFastTrackButton();
      }
    }
  }

  _showFastTrackButton() {
    const action = this.model.get('action');
    const isStartOrStartCert = action.actionType === TopicLevelActionType.START || action.actionType === TopicLevelActionType.START_CERT;
    if (action.fastTrackEligible && isStartOrStartCert) {
      this.ui.fastTrackButton.show();
    }
  }

  _showPreviewButton() {
    const moduleType = this.model.get('trainingModuleType');

    if (moduleType != null && TenantPropertyProvider.get().getProperty('trainingModulePreviewEnabled')) {
      this.ui.previewButton.show();
    }
  }

  _getActionButtonText() {
    const action = this.model.get('action');

    // Special case for topic level rows where RESTART needs to translate to a 'Re-take' button string
    if (action != null && action.actionType === TopicLevelActionType.RESTART) {
      action.actionType = TopicLevelActionType.RETAKE;
    }

    if (action != null && action.actionType === TopicLevelActionType.RESUME && action.fastTrackEligible) {
      action.actionType = TopicLevelActionType.RESUME_FAST_TRACK;
    }

    return TopicCTAButtonHelper.getButtonText(action);
  }

  _getProgressScore() {
    if (this.model.get('score') == null || this._isModuleOnlyTopic()) {
      return '-';
    }
    return this.model.get('score') + I18n.t('general.percent');

  }

  _getProgressIcon() {
    const progress = this.model.get('progress') || 'NotStarted';
    return TopicProgressEnum[progress];
  }

  _getProgressIconLabel() {
    let iconTitle = this._getProgressIcon();

    if (iconTitle === TopicProgressEnum.NotStarted) {
      iconTitle = 'notStarted';
    }

    return I18n.t(`hub.topicLevelStatus.${ iconTitle }`);
  }

  _getBlockingLevelNum() {
    const collection = this.model.collection;

    if (collection != null) {
      for (let levelIndex = indexOf(collection.models, this.model) - 1; levelIndex >= 0; levelIndex--) {
        const modelAtIndex = collection.at(levelIndex);
        const progress = modelAtIndex.get('progress') === null ? null : modelAtIndex.get('progress')
          .toLowerCase();

        if (progress === null || progress === TopicProgressEnum.NotStarted ) {
          return modelAtIndex.get('level');
        }
      }
    }

    return undefined;
  }

  _isModuleOnlyTopic() {
    return this.model.get('questionCount') === 0 && this.model.get('trainingModuleType') != null;
  }

  _isIntroApplicable() {
    return this.model.get('isIntroApplicable');
  }

  _isCertApplicable() {
    return this.model.get('isCertApplicable') && this.model.get('certDetails') != null;
  }

  _getBadgeIcon() {
    if (this._isCertApplicable()) {
      const status = this.model.get('certDetails').status;
      if (status === CertificationStatus.CERTIFIED) {
        return {
          class: 'certification certified',
          ariaLabel: I18n.t('knowledge.certificationLabel.CERTIFIED')
        };
      } else if (status === CertificationStatus.DUE) {
        return {
          class: 'certification due',
          ariaLabel: I18n.t('knowledge.certificationLabel.DUE')
        };
      } else if (status === CertificationStatus.OVERDUE) {
        return {
          class: 'certification overdue',
          ariaLabel: I18n.t('knowledge.certificationLabel.OVERDUE')
        };
      }
    }

    return {
      class: '',
      ariaLabel: ''
    };
  }
}

module.exports = TopicLevelRowView;
