const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { Wreqr } = require('Backbone');

const LearningObjectiveCardLayoutView = require('@common/components/learningObjectives/LearningObjectiveCardLayoutView');
const LearningObjectiveCardGraphicView = require('@common/components/learningObjectives/LearningObjectiveCardGraphicView');
const LearningObjectiveCardTagView = require('@common/components/learningObjectives/LearningObjectiveCardTagView');
const LearningObjectiveCardMetaDataView = require('@common/components/learningObjectives/LearningObjectiveCardMetaDataView');
const LearningObjectiveCardActionView = require('@common/components/learningObjectives/LearningObjectiveCardActionView');
const {
  EVENT_OBJECTIVE,
  TASK_OBJECTIVE,
  CERTIFICATION_OBJECTIVE,
  TOPIC_OBJECTIVE,
  EVALUATION_OBJECTIVE
} = require('@common/data/models/objectives/GuidedLearningObjectiveType');
const {
  START,
  RETAKE,
  LOCKED,
  VIEW,
  VIEW_INTERACTIVE,
  ENROLL,
  ENROLL_INTERACTIVE,
  NONE,
  RESUME,
  REASSIGN,
  RESUME_FAST_TRACK
} = require('@common/components/learningObjectives/LearningObjectiveCardActionType');
const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const AxonifyApps = require('@common/data/enums/AxonifyApps').default;

const _getGraphicRegionDefinition = function({
  guidedLearningObjectiveType,
  thumbnailImage
} = {}) {
  return {
    viewDefinition: {
      ViewClass: LearningObjectiveCardGraphicView,
      guidedLearningObjectiveType,
      thumbnailImage
    }
  };
};

const _getTagRegionDefinition = function({ guidedLearningObjectiveType } = {}) {
  return {
    viewDefinition: {
      ViewClass: LearningObjectiveCardTagView,
      guidedLearningObjectiveType
    }
  };
};

const _getMetaDataRegionDefinition = function({
  guidedLearningObjectiveType,
  numberOfQuestions,
  numberOfVideos,
  rewardPoints,
  completionDate,
  scheduledEventStartDate,
  eventMetaData,
  isLocked,
  isOverdue,
  daysUntilDue,
  isComplete,
  evaluationStatus,
  lastAttemptDate,
  isImported,
  isCompletedFastTrack
} = {}) {
  return {
    viewDefinition: {
      ViewClass: LearningObjectiveCardMetaDataView,
      guidedLearningObjectiveType,
      numberOfQuestions,
      numberOfVideos,
      rewardPoints,
      completionDate,
      scheduledEventStartDate,
      eventMetaData,
      isLocked,
      isOverdue,
      daysUntilDue,
      isComplete,
      evaluationStatus,
      lastAttemptDate,
      isImported,
      isCompletedFastTrack
    }
  };
};

const _getActionRegionDefinition = function({
  actionType,
  objectiveStarter,
  model,
  programId
} = {}) {
  const startObjectiveCallback = (actionType === REASSIGN)
    ? _getReassignTrainingCallback({
      model,
      programId
    })
    : _getStartObjectiveCallback({
      objectiveStarter,
      model
    });

  const isFastTrackEligible = model.get('actionItem')?.fastTrackEligible ?? false;

  return {
    viewDefinition: {
      ViewClass: LearningObjectiveCardActionView,
      actionType,
      startObjectiveCallback,
      name: model.getName(),
      programId,
      isFastTrackEligible
    }
  };
};

const _getActionType = function({
  guidedLearningObjectiveType,
  isLocked,
  isStartable,
  hasStarted,
  isComplete,
  eventCanDrillDown,
  isResumable,
  isImported,
  isReassignable,
  isFailed,
  isResumeFastTrack
} = {}) {
  let result = NONE;

  if (isLocked) {
    result = LOCKED;
  } else if (env.settings.app === AxonifyApps.MANAGER && isReassignable) { // Reassign button should only appear in LeaderZone
    result = REASSIGN;
  } else if (guidedLearningObjectiveType === TASK_OBJECTIVE) {
    result = isComplete ? VIEW_INTERACTIVE : VIEW;
  } else if (guidedLearningObjectiveType === EVALUATION_OBJECTIVE && !isImported) {
    result = (isStartable || isFailed) ? VIEW : VIEW_INTERACTIVE;
  } else if (guidedLearningObjectiveType === EVENT_OBJECTIVE && eventCanDrillDown) {
    result = isStartable ? ENROLL : ENROLL_INTERACTIVE;
  } else if (isStartable && isResumable) {
    result = isResumeFastTrack ? RESUME_FAST_TRACK : RESUME;
  } else if (isStartable && hasStarted) {
    result = RETAKE;
  } else if (isStartable) {
    result = START;
  }

  return result;
};

const _getCardActionCallback = function({
  guidedLearningObjectiveType,
  actionType,
  objectiveStarter,
  model,
  viewTopicDrillDown,
  programId
} = {}) {

  let startObjectiveCallback = null;

  if (
    [TASK_OBJECTIVE, EVENT_OBJECTIVE, EVALUATION_OBJECTIVE].includes(guidedLearningObjectiveType)
    && [VIEW, VIEW_INTERACTIVE, ENROLL, ENROLL_INTERACTIVE].includes(actionType)
  ) {
    startObjectiveCallback = _getStartObjectiveCallback({
      objectiveStarter,
      model
    });
  } else if (actionType === REASSIGN) {
    startObjectiveCallback = _getReassignTrainingCallback({
      model,
      programId
    });
  } else if (guidedLearningObjectiveType === TOPIC_OBJECTIVE || guidedLearningObjectiveType === CERTIFICATION_OBJECTIVE) {
    startObjectiveCallback = () => {
      viewTopicDrillDown(model.get('topicId'));
    };
  }

  return startObjectiveCallback;
};

const _getStartObjectiveCallback = function({
  objectiveStarter,
  model
} = {}) {
  return (fastTrack = false) => {
    if (fastTrack) {
      model.set('fastTrack', true);
    }
    objectiveStarter(model);
  };
};

const _getReassignTrainingCallback = function({
  model,
  programId
} = {}) {
  return () => {
    const glChannel = Wreqr.radio.channel('global');
    glChannel.commands.execute('reassignTraining', {
      pathItemId: model.get('entityId'),
      pathItemName: model.get('name'),
      pathItemType: model.get('type'),
      pathProgramId: programId
    });
  };
};

module.exports = {
  getObjectiveCardDefinition(objective, options) {
    const model = options.model;
    const guidedLearningObjectiveType = objective.getType();
    const guidedLearningObjectiveName = model.getName();
    const guideLearningObjectiveParentName = TenantPropertyProvider.get().getProperty('subjectNameOnPathCardsEnabled') ? model.getSubjectName() : undefined;
    const numberOfQuestions = model.getNumberOfQuestions();
    const numberOfVideos = model.getNumberOfVideos();
    const rewardPoints = model.getPointsAvailableForCompletion();
    const completionDate = model.getCompletionDate();
    const scheduledEventStartDate = guidedLearningObjectiveType === EVENT_OBJECTIVE ? model.getScheduledEventStartDate() : null;
    const isLocked = model.isLocked() || options.forceLockedAppearance;
    const isStartable = model.isStartable();
    const hasStarted = model.hasStarted();
    const isComplete = model.isComplete();
    const isResumable = model.isResumable();
    const objectiveStarter = options.objectiveStarter;
    const thumbnailImage = model.getThumbnailImage();
    const isDisabled = options.disabled;
    const viewTopicDrillDown = options.viewTopicDrillDown;

    const isEvent = guidedLearningObjectiveType === EVENT_OBJECTIVE;
    const eventCanDrillDown = isEvent ? model.canDrillDown() : false;

    const isCertification = guidedLearningObjectiveType === CERTIFICATION_OBJECTIVE;
    const isOverdue = isCertification ? model.getIsOverdue() : undefined;
    const daysUntilDue = isCertification ? model.getDaysUntilDue() : undefined;

    const isEvaluation = guidedLearningObjectiveType === EVALUATION_OBJECTIVE;
    const evaluationStatus = isEvaluation ? model.getEvaluationStatus() : null;
    const isFailed = isEvaluation ? evaluationStatus === EvaluationObjectiveStatus.FAILED : undefined;
    const lastAttemptDate = isEvaluation ? model.getLastAttemptDate() : null;
    const isImported = isEvaluation ? model.isImported() : null;
    const programId = options.programId;
    const isReassignable = model.isReassignable();
    const isCompletedFastTrack = model.isCompletedFastTrack();

    const isResumeFastTrack = isResumable ? model.isResumeFastTrack() : false;

    const actionType = _getActionType({
      guidedLearningObjectiveType,
      isLocked,
      isStartable,
      hasStarted,
      isComplete,
      eventCanDrillDown,
      isResumable,
      isImported,
      isReassignable,
      isFailed,
      isResumeFastTrack
    });

    const eventMetaData = {};
    if (guidedLearningObjectiveType === EVENT_OBJECTIVE) {
      eventMetaData.type = model.getEnrollmentType();
      eventMetaData.numScheduledEventsAvailable = model.getNumScheduledEventsAvailable();
    }

    const actionRegionDefinition = _getActionRegionDefinition({
      actionType,
      objectiveStarter,
      model,
      programId
    });

    const controllerDefinition = {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: LearningObjectiveCardLayoutView,
        name: guidedLearningObjectiveName,
        parentName: guideLearningObjectiveParentName,
        ariaHeaderLevel: 3,
        isOverdue,
        isDisabled,
        isFailed,
        actionCallback: _getCardActionCallback({
          guidedLearningObjectiveType,
          actionType,
          objectiveStarter,
          model,
          viewTopicDrillDown,
          programId
        })
      },
      regionControllers: {
        graphicRegion: _getGraphicRegionDefinition({
          guidedLearningObjectiveType,
          thumbnailImage
        }),
        tagRegion: _getTagRegionDefinition({ guidedLearningObjectiveType }),
        metaDataRegion: _getMetaDataRegionDefinition({
          guidedLearningObjectiveType,
          numberOfQuestions,
          numberOfVideos,
          rewardPoints,
          completionDate,
          scheduledEventStartDate,
          eventMetaData,
          isLocked,
          isOverdue,
          daysUntilDue,
          isComplete,
          evaluationStatus,
          lastAttemptDate,
          isImported,
          isCompletedFastTrack
        }),
        actionRegion: actionRegionDefinition,
        actionRegionMobile: actionRegionDefinition
      }
    };

    return controllerDefinition;
  }
};
