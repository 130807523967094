const Backbone = require('Backbone');
const _ = require('underscore');

const AssessmentTopicOption = require('@common/data/models/assessments/AssessmentTopicOption');
const AssessmentExamOption = require('@common/data/models/assessments/AssessmentExamOption');
const AssessmentType = require('@common/data/enums/AssessmentType');

const TOPIC_OPTION_IS_PASSED = (option) => {
  return option.hasPassedLastAttempt();
};

const TOPIC_OPTION_IS_LOCKED = (option) => {
  return option.isLocked();
};

const EXAM_TOPIC_OPTION_IS_COMPLETED = (option) => {
  const lastResult = option.get('lastRelevantResult');
  const attemptsRemaining = option.get('attemptsRemaining');

  return lastResult && lastResult.completed && ( lastResult.passed || attemptsRemaining === 0);
};

class AssessmentOptionsList extends Backbone.Collection {
  initialize(models, options = {}) {
    // passDateThreshold tells the server how far back to
    // include passed assessments
    ({
      passDateThreshold: this.passDateThreshold,
      sessionModel: this.sessionModel
    } = options);

    this.finishCount = 0;
    this._originalModelList = new Backbone.Collection();

    this.listenTo(this, 'sync', this.onModelSync);
    this.listenTo(this, 'change:lastRelevantResult', this.onUpdateFinishedCount);
  }

  parse(response) {
    const items = response.result.items;
    const assessmentType = this.getAssessmentType();

    items.forEach((item) => {
      const criteria = { type: assessmentType };

      // FormalExam is an exception - has no topicId or level data so we find in progress assessment matches via the program ID
      if (assessmentType === AssessmentType.FormalExamTraining) {
        Object.assign(criteria, {
          programId: item.program.id
        });
      } else {
        Object.assign(criteria, {
          topicId: item.topic.id,
          level: item.level
        });
      }

      this.sessionModel.extendWithAssessmentGetter(item, criteria);

      item[AssessmentTopicOption.FIELDS.FOR_ASSESSMENT_TYPE] = assessmentType;
    });

    return items;
  }

  onModelSync() {
    this.resetOriginalModelList();
    this.updateFinishedCount();
  }

  onUpdateFinishedCount() {
    this.updateFinishedCount();
  }

  getOriginalLength() {
    return this._originalModelList.length;
  }

  stringifyOriginalList() {
    return `${ JSON.stringify(this._originalModelList.toJSON()) }`;
  }

  resetOriginalModelList() {
    this._originalModelList.reset(this.toArray());
    this.updateFinishedCount();
  }

  updateFinishedCount() {
    this.finishCount = this._getFinishedOptions().length;
    this.trigger('change:finishCount', this, this.finishCount);
  }

  hasSomeFinishedAssessment() {
    return this.getFinishedCount() > 0;
  }

  getFinishedCount() {
    return this.finishCount;
  }

  _getFinishedOptions() {
    return this._originalModelList.filter((option) => {
      return option.hasPassedLastAttempt();
    });
  }

  getNextUnfinishedItem() {
    const item = this
      .chain()
      .reject(TOPIC_OPTION_IS_LOCKED)
      .reject(TOPIC_OPTION_IS_PASSED)
      .value()
      .shift();

    return item || null;
  }

  fetch(options = {}) {
    return super.fetch(_.extend({}, {data: {passDateThreshold: this.passDateThreshold}}, options));
  }

  clearSearch() {
    return this.reset(this._originalModelList.toArray());
  }

  hasFastTrackAssessment() {
    return this._originalModelList.some((option) => {
      return option.get('fastTrackEligible');
    });
  }
}

class FormalExamOptionList extends AssessmentOptionsList {

  get model() {
    return AssessmentExamOption;
  }

  apiEndpoint() {
    return '/formalExamItems/available';
  }

  _getFinishedOptions() {
    return this.filter(EXAM_TOPIC_OPTION_IS_COMPLETED);
  }

  getNextUnfinishedItem() {
    const item = this
      .chain()
      .reject(TOPIC_OPTION_IS_LOCKED)
      .reject(EXAM_TOPIC_OPTION_IS_COMPLETED)
      .value()
      .shift();

    return item || null;
  }

  search(query = '') {
    if (query.trim() !== '') {
      const filterTextList = query.toLowerCase().split(/\s/);
      const filteredList = this._originalModelList.filter((item) => {
        return _.some(filterTextList, (filterFragment) => {
          return item.get('program').name.toLowerCase().indexOf(filterFragment) >= 0;
        });
      });

      this.reset(filteredList);
    }
  }

  getAssessmentType() {
    return AssessmentType.FormalExamTraining;
  }
}

class AssessmentTopicOptionList extends AssessmentOptionsList {

  get model() {
    return AssessmentTopicOption;
  }

  search(query = '') {
    if (query.trim() !== '') {
      const filterTextList = query.toLowerCase().split(/\s/);
      const filteredList = this._originalModelList.filter((item) => {
        return _.some(filterTextList, (filterFragment) => {
          return (item.get('category').name.toLowerCase().indexOf(filterFragment) >= 0)
          || (item.get('topic').name.toLowerCase().indexOf(filterFragment) >= 0)
          || (item.get('subject').name.toLowerCase().indexOf(filterFragment) >= 0);
        });
      });

      return this.reset(filteredList);
    }

    return undefined;
  }

  findTopicOption(topicId, level) {
    return this._originalModelList.find((optionModel) => {
      const {
        topic: modelTopic,
        level: modelLevel
      } = optionModel.pick('topic', 'level');

      return topicId === modelTopic.id && level === modelLevel;
    });
  }
}

class CertificationTopicOptionList extends AssessmentTopicOptionList {
  apiEndpoint() {
    return '/certificationItems/available';
  }

  getAssessmentType() {
    return AssessmentType.CertificationTraining;
  }
}

class IntroductoryTopicOptionList extends AssessmentTopicOptionList {
  apiEndpoint() {
    return '/introTrainingItems/available';
  }

  getAssessmentType() {
    return AssessmentType.IntroductoryTraining;
  }
}

class RefresherTopicOptionList extends AssessmentTopicOptionList {
  apiEndpoint() {
    return '/refresherItems/available';
  }

  getAssessmentType() {
    return AssessmentType.RefresherTraining;
  }
}

module.exports = {
  CertificationTopicOptionList,
  IntroductoryTopicOptionList,
  RefresherTopicOptionList,
  FormalExamOptionList
};
